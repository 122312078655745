import React, { useState } from 'react';
import { useGenerateDbtJobsMutation, usePipelineMutation } from 'app/createApi';
import { useAppSelector, useAppDispatch } from 'app/hooks';
import { selectProjectPath, selectBranch, setPipelineId } from 'app/sharedSlice';
import { selectSnowflakeAccount, selectSelectedDatabase } from 'app/snowflakeSlice';
import {
  selectSeedJobSelected,
  selectTestSourcesJobSelected,
  selectBuildModelsJobSelected,
  selectTestModelsJobSelected,
  selectDocumentationJobSelected,
  selectSeedSelector,
  selectSourceSelector,
  selectModelSelector,
  selectSelectorTags,
  selectSelectorFiles,
  selectDbtVersion,
  setSeedJobSelected,
  setTestSourcesJobSelected,
  setBuildModelsJobSelected,
  setTestModelsJobSelected,
  setDocumentationJobSelected,
  setSeedSelector,
  setSourceSelector,
  setModelSelector,
} from '../reducers/dbtMigrationSlice';
import StepWrapper from 'components/StepWrapper';
import DbtSelectors from '../components/DbtSelectors';
import LoadingAndErrorSection from 'components/LoadingAndErrorSection';

export interface ModelSelectionStepProps {
  onBack: () => void;
  onContinue: () => void;
}

export default function ModelSelectionStep(props: ModelSelectionStepProps): JSX.Element {
  const dispatch = useAppDispatch();

  const [loading, setLoading] = useState<boolean>(false);
  const [failed, setFailed] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string>('Failed to set up pipeline');

  const projectPath = useAppSelector(selectProjectPath);
  const branch = useAppSelector(selectBranch);
  const snowflakeAccount = useAppSelector(selectSnowflakeAccount);
  const selectedDatabase = useAppSelector(selectSelectedDatabase);
  const dbtVersion = useAppSelector(selectDbtVersion);

  const seedJobSelected = useAppSelector(selectSeedJobSelected);
  const testSourcesJobSelected = useAppSelector(selectTestSourcesJobSelected);
  const buildModelsJobSelected = useAppSelector(selectBuildModelsJobSelected);
  const testModelsJobSelected = useAppSelector(selectTestModelsJobSelected);
  const documentationJobSelected = useAppSelector(selectDocumentationJobSelected);
  const seedSelector = useAppSelector(selectSeedSelector);
  const sourceSelector = useAppSelector(selectSourceSelector);
  const modelSelector = useAppSelector(selectModelSelector);
  const selectorTags = useAppSelector(selectSelectorTags);
  const selectorFiles = useAppSelector(selectSelectorFiles);

  const [generateDbtJobs] = useGenerateDbtJobsMutation();
  const [pipeline] = usePipelineMutation();

  const onContinue = (): void => {
    setLoading(true);
    setFailed(false);

    const jobList = [];
    if (seedJobSelected) {
      jobList.push('SEED');
    }
    if (testSourcesJobSelected) {
      jobList.push('SOURCES');
    }
    if (buildModelsJobSelected || testModelsJobSelected || documentationJobSelected) {
      jobList.push('BUILD');
    }
    if (testModelsJobSelected) {
      jobList.push('TEST');
    }
    if (documentationJobSelected) {
      jobList.push('DOCS');
    }

    generateDbtJobs({
      projectName: projectPath,
      masterDatabase: selectedDatabase,
      account: snowflakeAccount,
      dbtVersion,
      branch,
      jobList,
      seedSelector,
      sourceSelector,
      modelSelector,
    })
      .unwrap()
      .then((res: any) => {
        console.log(res);
        pipeline({ dataProductName: 'full', projectName: projectPath, branch, mergeMain: false, camelCase: false })
          .unwrap()
          .then((res: any) => {
            dispatch(setPipelineId(res));
            props.onContinue();
          })
          .catch((err) => {
            console.log(err);
            setFailed(true);
            setErrorMessage(err.data);
          })
          .finally(() => {
            setLoading(false);
          });
      })
      .catch((err) => {
        console.log(err);
        setFailed(true);
        setErrorMessage(err.data);
        setLoading(false);
      });
  };

  return (
    <StepWrapper
      title="Pipeline configuration"
      subtitle="Which jobs do you want to include in the pipeline?"
      onBack={() => props.onBack()}
      onContinue={() => onContinue()}
      isLoading={loading}
    >
      <div className="w-3/4 2xl:w-1/2 wide:w-1/3 mx-[auto] space-y-4">
        <div className="relative space-y-2 cursor-default rounded-lg border border-gray-300 px-6 py-5 shadow-sm hover:border-gray-400">
          <h4 className="text-base font-semibold tracking-tight text-gray-900">Seed Job</h4>
          <div className="absolute top-4 right-4">
            <input
              type="checkbox"
              checked={seedJobSelected}
              onChange={() => dispatch(setSeedJobSelected(!seedJobSelected))}
              className="w-4 h-4 rounded border-gray-300 cursor-pointer"
            />
          </div>
          <p className="text-sm text-gray-600">
            The dbt seed job will load CSV files located in you project, it is used to ingest data from files
          </p>
          <DbtSelectors
            label="Seed selector"
            tags={selectorTags.map((tag) => 'tag:' + tag)}
            files={selectorFiles}
            selected={seedSelector}
            setSelected={(value) => dispatch(setSeedSelector(value))}
          />
        </div>

        <div className="relative space-y-2 cursor-default rounded-lg border border-gray-300 px-6 py-5 shadow-sm hover:border-gray-400">
          <h4 className="text-base font-semibold tracking-tight text-gray-900">Test Sources Job</h4>
          <div className="absolute top-4 right-4">
            <input
              type="checkbox"
              checked={testSourcesJobSelected}
              onChange={() => dispatch(setTestSourcesJobSelected(!testSourcesJobSelected))}
              className="w-4 h-4 rounded border-gray-300 cursor-pointer"
            />
          </div>
          <p className="text-sm text-gray-600">
            This job aims to identify any inconsistencies, anomalies or errors in the source data before using it for
            building the models. Please specify the selector of your sources
          </p>
          <DbtSelectors
            label="Source selector"
            tags={selectorTags.map((tag) => 'tag:' + tag)}
            files={selectorFiles}
            selected={sourceSelector}
            setSelected={(value) => dispatch(setSourceSelector(value))}
          />
        </div>

        <div className="relative space-y-2 cursor-default rounded-lg border border-gray-300 px-6 py-5 shadow-sm hover:border-gray-400">
          <h4 className="text-base font-semibold tracking-tight text-gray-900">Build Models Job</h4>
          <div className="absolute top-4 right-4">
            <input
              type="checkbox"
              checked={buildModelsJobSelected || testModelsJobSelected || documentationJobSelected}
              onChange={() => dispatch(setBuildModelsJobSelected(!buildModelsJobSelected))}
              className="w-4 h-4 rounded border-gray-300 cursor-pointer"
            />
          </div>
          <p className="text-sm text-gray-600">
            This job specifies the models that will be built by the DataOps pipeline. Please specify the DBT selector
            for the models that you want to build
          </p>
          <DbtSelectors
            label="Model selector"
            tags={selectorTags.map((tag) => 'tag:' + tag)}
            files={selectorFiles}
            selected={modelSelector}
            setSelected={(value) => dispatch(setModelSelector(value))}
          />
        </div>

        <div className="relative space-y-2 cursor-default rounded-lg border border-gray-300 px-6 py-5 shadow-sm hover:border-gray-400">
          <h4 className="text-base font-semibold tracking-tight text-gray-900">Test Models Job</h4>
          <div className="absolute top-4 right-4">
            <input
              type="checkbox"
              checked={testModelsJobSelected}
              onChange={() => dispatch(setTestModelsJobSelected(!testModelsJobSelected))}
              className="w-4 h-4 rounded border-gray-300 cursor-pointer"
            />
          </div>
          <p className="text-sm text-gray-600">
            This step aims to test the models that had been built in the previous step and build confidence in the
            quality of the data product. It used the selector provided in the Build step
          </p>
        </div>

        <div className="relative space-y-2 cursor-default rounded-lg border border-gray-300 px-6 py-5 shadow-sm hover:border-gray-400">
          <h4 className="text-base font-semibold tracking-tight text-gray-900">Documentation Job</h4>
          <div className="absolute top-4 right-4">
            <input
              type="checkbox"
              checked={documentationJobSelected}
              onChange={() => dispatch(setDocumentationJobSelected(!documentationJobSelected))}
              className="w-4 h-4 rounded border-gray-300 cursor-pointer"
            />
          </div>
          <p className="text-sm text-gray-600">
            This job generates documentation for the models that have been build during the Build Models step
          </p>
        </div>

        <LoadingAndErrorSection isLoading={loading} isFailed={failed} errorMessage={errorMessage} hideLoading={true} />
      </div>
    </StepWrapper>
  );
}
