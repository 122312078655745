import type { IStep } from 'utils/types';

const defaultSteps: IStep[] = [
  {
    name: 'Target project',
    description: 'Where would you like to create the new project?',
    disabled: false,
  },
  {
    name: 'Source project',
    description: 'Select a source of your dbt project',
    disabled: false,
  },
  {
    name: 'Target database',
    description: 'Select the target database',
    disabled: false,
  },
];

export default function useDbtMigrationSteps(): IStep[] {
  return defaultSteps;
}
