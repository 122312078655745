import React, { useState, useRef } from 'react';
import axios from 'axios';
import { ArrowTopRightOnSquareIcon } from '@heroicons/react/24/outline';
import { Switch, Field, Label } from '@headlessui/react';
import { useAppSelector, useAppDispatch } from 'app/hooks';
import { selectProjectPath, selectBranch } from 'app/sharedSlice';
import {
  selectDbtProjectFilename,
  selectDbtVersion,
  selectCompileDbt,
  selectUseSampleProject,
  selectHasToUploadProject,
  setDbtProjectFilename,
  setDbtVersion,
  setCompileDbt,
  setSelectorTags,
  setSelectorFiles,
  setUseSampleProject,
  setHasToUploadProject,
} from '../reducers/dbtMigrationSlice';
import { FolderIcon, CheckCircleIcon, ExclamationTriangleIcon } from '@heroicons/react/20/solid';
import StepWrapper from 'components/StepWrapper';
import LoadingAndErrorSection from 'components/LoadingAndErrorSection';
import DropdownSingleselect from 'components/DropdownSingleselect';
import { getConfig } from 'config/config-helper';
import { classNames } from 'utils/styleUtils';

const { dataopsliveBaseUrl } = getConfig();

const supportedDbtVersions = ['1.4', '1.5', '1.6', '1.7', '1.8', '1.9'];

const tabs = ['Upload project', 'Use example'];

export interface SourceProjectStepProps {
  onBack: () => void;
  onContinue: () => void;
}

export default function SourceProjectStep(props: SourceProjectStepProps): JSX.Element {
  const dispatch = useAppDispatch();
  const [loading, setLoading] = useState<boolean>(false);
  const [loadingUpload, setLoadingUpload] = useState<boolean>(false);
  const [success, setSuccess] = useState<boolean>(false);
  const [failed, setFailed] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string>('Failed to upload project');

  const [responseMessage, setResponseMessage] = useState<string>();
  const [numberofFilesUploaded, setNumberOfFilesUploaded] = useState<number>();

  const projectPath = useAppSelector(selectProjectPath);
  const branch = useAppSelector(selectBranch);
  const dbtProjectFilename = useAppSelector(selectDbtProjectFilename);
  const dbtVersion = useAppSelector(selectDbtVersion);
  const compileDbt = useAppSelector(selectCompileDbt);
  const useSampleProject = useAppSelector(selectUseSampleProject);
  const hasToUploadProject = useAppSelector(selectHasToUploadProject);

  const [filename, setFilename] = useState<string>(dbtProjectFilename);

  const fileInputRef = useRef<HTMLInputElement>(null);

  const onFileChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
    if (e.target.files !== null) {
      setFilename(e.target.files?.[0].name);
      dispatch(setDbtProjectFilename(e.target.files?.[0].name));
      onProjectUpload(false, e.target.files?.[0].name);
    }
  };

  const catchError = (error: any): void => {
    console.log('dbt-upload failed');
    setFailed(true);
    setErrorMessage(error.message);
    setSuccess(false);
    setLoading(false);
    setLoadingUpload(false);
  };

  const onProjectUpload = (continueOnSuccess: boolean, zipFilename: string): void => {
    dispatch(setUseSampleProject(false));
    if (fileInputRef !== null) {
      setLoading(true);
      if (!continueOnSuccess) {
        setLoadingUpload(true);
      }
      setFailed(false);
      const fileReader = new FileReader();
      fileReader.onload = (event) => {
        const data = event.target?.result;
        const uploadUrl = `/api/v1/dbt-upload/process?branch=${branch}&repository=${dataopsliveBaseUrl}/${projectPath
          .toLowerCase()
          .split(' ')
          .join('-')}.git&dbt=${dbtVersion}`;

        if (event.target !== null) {
          console.log('Upload successful');
          axios
            .get('/api/v1/dbt-upload/url')
            .then((urlRes: any) => {
              const form = new FormData();
              for (const key in urlRes.data.fields) {
                form.append(key, urlRes.data.fields[key]);
              }
              form.append('file', new Blob([data as ArrayBuffer], { type: 'application/zip' }));
              axios
                .post(urlRes.data.url, form, {
                  transformRequest: (data, headers) => {
                    delete headers.common;
                    delete headers.Authorization;
                    return data;
                  },
                })
                .then((uploadRes: any) => {
                  const key = String(urlRes.data.fields.key);
                  const processUrl = `${uploadUrl}&file_key=${key}`;
                  axios
                    .get(processUrl)
                    .then((res: any) => {
                      console.log(res.data);

                      if (res.data.objects_stats !== undefined) {
                        setResponseMessage(res.data.objects_stats);
                      }

                      if (res.data.selectors?.tags !== undefined) {
                        dispatch(setSelectorTags(res.data.selectors?.tags));
                      }

                      if (res.data.selectors?.files !== undefined) {
                        setNumberOfFilesUploaded(res.data.selectors?.files?.length);
                        dispatch(setSelectorFiles(res.data.selectors?.files));
                      }

                      dispatch(setHasToUploadProject(false));
                      setSuccess(true);
                    })
                    .catch(catchError)
                    .finally(() => {
                      setLoading(false);
                      if (!continueOnSuccess) {
                        setLoadingUpload(false);
                      }
                    });
                })
                .catch(catchError);
            })
            .catch(catchError);
        }
      };
      if (fileInputRef.current?.files?.[0] !== undefined) {
        fileReader.readAsArrayBuffer(fileInputRef.current?.files?.[0]);
      }
    }
  };

  const onContinue = (): void => {
    if (useSampleProject) {
      setLoading(true);
      setFailed(false);

      setFilename('jaffle-shop-main.zip');

      const sampleUrl = `/api/v1/dbt-upload/sample?sample_project=jaffle-shop-main.zip&branch=${branch}&repository=${dataopsliveBaseUrl}/${projectPath
        .toLowerCase()
        .split(' ')
        .join('-')}.git&dbt=1.7`;

      axios
        .get(sampleUrl)
        .then((res: any) => {
          console.log(res.data);

          dispatch(setDbtProjectFilename('jaffle-shop-main.zip'));

          if (res.data.objects_stats !== undefined) {
            setResponseMessage(res.data.objects_stats);
          }

          if (res.data.selectors?.tags !== undefined) {
            dispatch(setSelectorTags(res.data.selectors?.tags));
          }

          if (res.data.selectors?.files !== undefined) {
            setNumberOfFilesUploaded(res.data.selectors?.files?.length);
            dispatch(setSelectorFiles(res.data.selectors?.files));
          }

          setSuccess(true);

          props.onContinue();
        })
        .catch(catchError)
        .finally(() => {
          setLoading(false);
        });
    } else if (hasToUploadProject) {
      onProjectUpload(true, filename);
    } else {
      props.onContinue();
    }
  };

  return (
    <StepWrapper
      title="Source project"
      subtitle="Select a source of your dbt project"
      onBack={() => props.onBack()}
      onContinue={() => onContinue()}
      continueDisabled={(dbtProjectFilename === '' && !useSampleProject) || loading || loadingUpload}
      isLoading={loading && !loadingUpload}
    >
      <div className="w-full xl:w-3/4 wide:w-1/2 mx-[auto] flex flex-col items-center mb-2">
        <div className="w-4/5 mx-[auto]">
          <div className="border-b border-gray-200 mb-6">
            <nav aria-label="Tabs" className="-mb-px flex justify-between rounded-md">
              {tabs.map((tab) => (
                <div
                  key={tab}
                  onClick={() => {
                    if (useSampleProject) {
                      dispatch(setDbtProjectFilename(''));
                      setFilename('');
                    }
                    dispatch(setUseSampleProject(tab === 'Use example'));
                    setFailed(false);
                    setSuccess(false);
                    setLoading(false);
                    setLoadingUpload(false);
                  }}
                  className={classNames(
                    (tab === 'Upload project' && !useSampleProject) || (tab === 'Use example' && useSampleProject)
                      ? 'border-dataops-secondary-blue text-dataops-secondary-blue bg-dataops-secondary-blue/5'
                      : 'border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700',
                    'flex-1 whitespace-nowrap border-b-2 px-1 py-2 text-center text-sm font-medium cursor-pointer',
                  )}
                >
                  {tab}
                </div>
              ))}
            </nav>
          </div>
        </div>
        <div className="w-full flex flex-col items-center space-y-4">
          {!useSampleProject && (
            <>
              <div className="w-4/5 mx-[auto] text-sm text-gray-600">
                Upload a ZIP file containing your dbt project and choose the dbt version you are using.
              </div>
              <div className="w-full flex justify-evenly items-center">
                <div>
                  <DropdownSingleselect
                    label="dbt version"
                    options={supportedDbtVersions}
                    selected={dbtVersion}
                    setSelected={(value) => {
                      dispatch(setDbtVersion(value));
                      dispatch(setHasToUploadProject(true));
                    }}
                  />
                </div>
                <Field as="div" className="my-[auto] flex flex-col items-center justify-center mb-1">
                  <Switch
                    checked={compileDbt}
                    onChange={() => dispatch(setCompileDbt(!compileDbt))}
                    className="group relative inline-flex h-5 w-10 flex-shrink-0 cursor-pointer items-center justify-center rounded-full focus:outline-none"
                  >
                    <span className="sr-only">Use setting</span>
                    <span
                      aria-hidden="true"
                      className="pointer-events-none absolute h-full w-full rounded-md bg-white"
                    />
                    <span
                      aria-hidden="true"
                      className={classNames(
                        compileDbt ? 'bg-dataops-primary-blue' : 'bg-gray-200',
                        'pointer-events-none absolute mx-auto h-4 w-9 rounded-full transition-colors duration-200 ease-in-out',
                      )}
                    />
                    <span
                      aria-hidden="true"
                      className={classNames(
                        compileDbt ? 'translate-x-5' : 'translate-x-0',
                        'pointer-events-none absolute left-0 inline-block h-5 w-5 transform rounded-full border border-gray-200 bg-white shadow ring-0 transition-transform duration-200 ease-in-out',
                      )}
                    />
                  </Switch>
                  <Label as="span" className="ml-1 cursor-default">
                    <span className="text-sm font-medium text-gray-700">Compile dbt</span>
                  </Label>
                </Field>
              </div>
              <div className="w-4/5 mt-2 flex justify-center rounded-lg border border-dashed border-gray-900/25 px-6 py-10">
                <div className="text-center flex flex-col items-center">
                  <FolderIcon className="mx-auto h-12 w-12 text-gray-300" aria-hidden="true" />
                  <p className="text-sm text-gray-800">Upload your own dbt project</p>
                  <p className="text-sm text-gray-500">or use a ready-to-go example project to get started</p>
                  {filename !== '' && (
                    <div className="flex items-center mt-4">
                      <div className="text-sm text-gray-600 font-semibold">{filename}</div>
                      {loadingUpload && !failed && (
                        <div className="ml-2 min-h-4 flex items-center justify-center">
                          <div
                            className="animate-spin inline-block w-4 h-4 border-[1.5px] border-current border-t-transparent text-cyan-800 rounded-full"
                            role="status"
                            aria-label="loading"
                          >
                            <span className="sr-only">Loading...</span>
                          </div>
                        </div>
                      )}
                    </div>
                  )}
                  <div className="mt-4 flex items-center justify-center space-x-2">
                    <label
                      htmlFor="file-upload"
                      className="relative cursor-pointer rounded-md bg-white font-semibold text-dataops-secondary-blue hover:text-hover-secondary-blue"
                    >
                      <div
                        className={classNames(
                          filename === ''
                            ? 'bg-dataops-secondary-blue hover:bg-hover-secondary-blue text-white'
                            : 'bg-white font-semibold text-gray-900 shadow-sm hover:bg-gray-50 ring-1 ring-inset ring-gray-300',
                          'rounded-md w-[8rem] px-3 py-2 text-sm font-semibold shadow-sm disabled:bg-gray-400',
                        )}
                      >
                        {filename === '' ? 'Upload' : 'Change'}
                      </div>
                      <input
                        type="file"
                        accept=".zip"
                        onChange={onFileChange}
                        ref={fileInputRef}
                        id="file-upload"
                        name="file-upload"
                        className="sr-only"
                      />
                    </label>
                    <button
                      className="rounded-md w-[8rem] bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm hover:bg-gray-50 ring-1 ring-inset ring-gray-300"
                      onClick={() => dispatch(setUseSampleProject(true))}
                    >
                      Use example
                    </button>
                  </div>
                </div>
              </div>
              {success && (
                <div
                  className={classNames(
                    responseMessage === 'Compile unsuccessful.' ? 'bg-yellow-50' : 'bg-green-50',
                    'w-4/5 mx-[auto] rounded-md p-4',
                  )}
                >
                  <div className="flex">
                    <div className="flex-shrink-0">
                      {responseMessage === 'Compile unsuccessful.' ? (
                        <ExclamationTriangleIcon className="h-5 w-5 text-yellow-400" aria-hidden="true" />
                      ) : (
                        <CheckCircleIcon className="h-5 w-5 text-green-400" aria-hidden="true" />
                      )}
                    </div>
                    <div className="ml-3">
                      <h3
                        className={classNames(
                          responseMessage === 'Compile unsuccessful.' ? 'text-yellow-800' : 'text-green-800',
                          'text-sm font-medium',
                        )}
                      >
                        {responseMessage === 'Compile unsuccessful.'
                          ? 'Project successfully uploaded, but the compilation failed'
                          : 'Project successfully uploaded'}
                      </h3>
                      <div className="flex flex-wrap">
                        {responseMessage !== undefined && responseMessage !== '' && (
                          <div
                            className={classNames(
                              responseMessage === 'Compile unsuccessful.' ? 'text-yellow-700' : 'text-green-700',
                              'mt-2 mr-6 text-sm',
                            )}
                          >
                            <p>
                              <span
                                className={classNames(
                                  responseMessage === 'Compile unsuccessful.' ? 'text-yellow-900' : 'text-green-900',
                                  'font-semibold',
                                )}
                              >
                                Message:
                              </span>{' '}
                              {responseMessage === 'Compile unsuccessful.'
                                ? 'After finishing the flow, please use our Develop cloud IDE to fix the errors in the project. It comes with an AI assistant that will help you along the way!'
                                : responseMessage}
                            </p>
                          </div>
                        )}
                        {numberofFilesUploaded !== undefined && (
                          <div
                            className={classNames(
                              responseMessage === 'Compile unsuccessful.' ? 'text-yellow-700' : 'text-green-700',
                              'mt-2 text-sm',
                            )}
                          >
                            <p className="whitespace-nowrap">
                              <span
                                className={classNames(
                                  responseMessage === 'Compile unsuccessful.' ? 'text-yellow-900' : 'text-green-900',
                                  'font-semibold',
                                )}
                              >
                                Files uploaded:
                              </span>{' '}
                              {numberofFilesUploaded}
                            </p>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </>
          )}

          {useSampleProject && (
            <div className="w-4/5 mx-[auto] flex flex-col items-center">
              <div className="mb-4 text-sm text-gray-700">
                Use a ready-to-go dbt project to get started. Using this project requires no additional setup. Press
                Continue to proceed.
              </div>
              <div className="w-full relative max-w-[25rem] mx-[auto] mt-4 overflow-hidden rounded-xl border border-gray-400">
                <input
                  checked={true}
                  name="snowflake-account"
                  type="radio"
                  className="absolute top-3 right-3 h-4 w-4 border-gray-300 text-dataops-secondary-blue focus:ring-dataops-secondary-blue cursor-pointer"
                />
                <div className="flex items-center gap-x-4 border-b border-gray-900/5 bg-gray-50 px-4 py-2">
                  <img
                    alt="dbt logo"
                    src="/static/dbt-logo.png"
                    className="size-12 p-1 object-contain flex-none rounded-full bg-white ring-1 ring-gray-900/10"
                  />
                  <a className="flex" href="https://github.com/dbt-labs/jaffle-shop" target="_blank" rel="noreferrer">
                    <div className="text-xl font-[550] text-gray-900">Jaffle Shop</div>
                    <ArrowTopRightOnSquareIcon className="size-3 ml-0.5 mt-1 text-gray-900" />
                  </a>
                </div>
                <div className="divide-y divide-gray-100 px-4 py-2 text-sm/6 text-gray-600">
                  This is a sandbox project for exploring the basic functionality and latest features of dbt. It&apos;s
                  based on a fictional restaurant called the Jaffle Shop that serves jaffles.
                </div>
              </div>
            </div>
          )}

          <div className="w-4/5 mx-[auto]">
            <LoadingAndErrorSection
              isLoading={loading}
              isFailed={failed}
              errorMessage={errorMessage}
              hideLoading={true}
            />
          </div>
        </div>
      </div>
    </StepWrapper>
  );
}
