import React from 'react';
import { useTooltip, useTooltipInPortal, defaultStyles } from '@visx/tooltip';
import { localPoint } from '@visx/event';
import { classNames } from 'utils/styleUtils';

const tooltipStyles = {
  ...defaultStyles,
  backgroundColor: '#4b5563',
  color: 'white',
  fontSize: 12,
  zIndex: 30,
  maxWidth: 250,
};

interface OutlineButtonWithTooltipProps {
  label: string;
  onClick: () => void;
  tooltip: string;
  style?: string;
}

interface TooltipData {
  description: string;
}

export default function OutlineButtonWithTooltip(props: OutlineButtonWithTooltipProps): JSX.Element {
  const { tooltipOpen, tooltipLeft, tooltipTop, tooltipData, hideTooltip, showTooltip } = useTooltip<TooltipData>();
  const { containerRef, TooltipInPortal } = useTooltipInPortal({
    scroll: true,
  });

  return (
    <>
      <button
        type="button"
        onClick={() => props.onClick()}
        className={classNames(
          props.style ?? '',
          'rounded-full ml-1 bg-white border border-dataops-secondary-blue px-1 text-sm shadow-sm text-dataops-secondary-blue hover:bg-dataops-secondary-blue/5',
        )}
        ref={containerRef}
        onMouseLeave={() => {
          hideTooltip();
        }}
        onMouseMove={(event) => {
          const eventSvgCoords = localPoint(event);
          showTooltip({
            tooltipData: { description: props.tooltip },
            tooltipTop: eventSvgCoords?.y,
            tooltipLeft: eventSvgCoords?.x,
          });
        }}
      >
        {props.label}
      </button>
      {tooltipOpen && tooltipData !== undefined && (
        <TooltipInPortal top={tooltipTop} left={tooltipLeft} style={tooltipStyles}>
          <div className="text-sm">{tooltipData.description}</div>
        </TooltipInPortal>
      )}
    </>
  );
}
