import React, { useEffect, Fragment } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { useAppSelector, useAppDispatch } from 'app/hooks';
import { selectCurrentStep, setCurrentStep, setCreateNewProject, setNewProjectName } from 'app/sharedSlice';
import { setIsProjectNameFromUrl } from 'features/dbt-migration/reducers/dbtMigrationSlice';
import TargetProjectStep from 'features/dbt-migration/steps/TargetProjectStep';
import SourceProjectStep from 'features/dbt-migration/steps/SourceProjectStep';
import TargetDatabaseStep from 'features/dbt-migration/steps/TargetDatabaseStep';
import ModelSelectionStep from 'features/dbt-migration/steps/ModelSelectionStep';
import DataProductSummaryStep from 'features/dbt-migration/steps/DataProductSummaryStep';
import useDbtMigrationSteps from 'features/dbt-migration/steps/dbtMigrationSteps';
import type { IStep } from 'utils/types';

export default function DbtMigrationView(): JSX.Element {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const [searchParams] = useSearchParams();
  const currentStep = useAppSelector(selectCurrentStep);
  const steps: IStep[] = useDbtMigrationSteps();

  const setStepAndSendMetric = (step: number): void => {
    dispatch(setCurrentStep(step));
  };

  useEffect(() => {
    if (searchParams.has('project_name')) {
      dispatch(setCurrentStep(0));
      dispatch(setCreateNewProject(true));
      dispatch(setNewProjectName(searchParams.get('project_name') as string));
      dispatch(setIsProjectNameFromUrl(true));
    }
  }, []);

  const getComponent = (name: string, index: number): React.ReactNode => {
    switch (name) {
      case 'Target project':
        return (
          <TargetProjectStep
            onBack={index === 0 ? () => navigate('/') : () => setStepAndSendMetric(index - 1)}
            onContinue={() => setStepAndSendMetric(index + 1)}
          />
        );
      case 'Source project':
        return (
          <SourceProjectStep
            onBack={() => setStepAndSendMetric(index - 1)}
            onContinue={() => setStepAndSendMetric(index + 1)}
          />
        );
      case 'Target database':
        return (
          <TargetDatabaseStep
            onBack={() => setStepAndSendMetric(index - 1)}
            onContinue={() => setStepAndSendMetric(index + 1)}
          />
        );
      case 'Pipeline configuration':
        return (
          <ModelSelectionStep
            onBack={() => setStepAndSendMetric(index - 1)}
            onContinue={() => setStepAndSendMetric(index + 1)}
          />
        );
      case 'Summary':
        return (
          <DataProductSummaryStep
            onBack={() => setStepAndSendMetric(index - 1)}
            onContinue={() => setStepAndSendMetric(index + 1)}
          />
        );
    }
  };

  return (
    <>
      {steps.map((step, index) => (
        <Fragment key={`${step.name}-${index}`}>{currentStep === index && getComponent(step.name, index)}</Fragment>
      ))}
    </>
  );
}
