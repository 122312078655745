import React from 'react';
import { CheckIcon } from '@heroicons/react/20/solid';
import { LightBulbIcon } from '@heroicons/react/24/outline';
import { useAppSelector, useAppDispatch } from 'app/hooks';
import { selectNumberOfSteps, selectProjectPath, selectBranch, setCurrentStep } from 'app/sharedSlice';
import { getConfig } from 'config/config-helper';

const { dataopsliveBaseUrl } = getConfig();

export default function DbtFlowComplete(): JSX.Element {
  const dispatch = useAppDispatch();
  const numberOfSteps = useAppSelector(selectNumberOfSteps);
  const projectPath = useAppSelector(selectProjectPath);
  const branch = useAppSelector(selectBranch);

  const pathForLink = projectPath.toLowerCase().split(' ').join('-');
  const devReadyLink = `https://develop.dataops.live/quickstart?autostart=true#/https://app.dataops.live/${pathForLink}/-/tree/${branch}/`;
  const dataOpsProjectLink = `${dataopsliveBaseUrl}/${pathForLink}/-/tree/${branch}`;

  return (
    <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
      <div className="relative w-[38rem] flex flex-col space-y-3 transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all data-[closed]:translate-y-4 data-[closed]:opacity-0 data-[enter]:duration-300 data-[leave]:duration-200 data-[enter]:ease-out data-[leave]:ease-in sm:my-8 sm:p-6 data-[closed]:sm:translate-y-0 data-[closed]:sm:scale-95">
        <div className="mx-auto flex size-12 items-center justify-center rounded-full bg-green-100">
          <CheckIcon aria-hidden="true" className="size-6 text-green-600" />
        </div>
        <div className="text-base font-semibold text-gray-900 text-center">Migration successful</div>
        <p className="w-full text-center mt-1 text-sm text-gray-600">
          Congratulations, you have just created your first DataOps project from your dbt core project. To learn more
          about the fundamental DataOps concepts, check out the following links:
        </p>
        <div className="mt-2 flex gap-2 flex-wrap">
          <a
            href="https://docs.dataops.live/docs/guides/dataops-fundamentals/project-structure/"
            target="_blank"
            rel="noreferrer"
          >
            <button
              type="button"
              className="flex rounded-full bg-white px-3 py-1.5 text-sm font-semibold text-dataops-primary-blue shadow-sm ring-1 ring-inset ring-dataops-primary-blue hover:text-hover-primary-blue"
            >
              <div className="flex-shrink-0">
                <LightBulbIcon className="h-5 w-5" aria-hidden="true" />
              </div>

              <div className="ml-1 flex-1 justify-between">
                <p className="text-sm">DataOps projects and pipelines</p>
              </div>
            </button>
          </a>
          <a
            href="https://docs.dataops.live/docs/dataops-development-environment/"
            target="_blank"
            rel="noreferrer"
            className="hidden xl:block"
          >
            <button
              type="button"
              className="flex rounded-full bg-white px-3 py-1.5 text-sm font-semibold text-dataops-primary-blue shadow-sm ring-1 ring-inset ring-dataops-primary-blue hover:text-hover-primary-blue"
            >
              <div className="flex-shrink-0">
                <LightBulbIcon className="h-5 w-5" aria-hidden="true" />
              </div>

              <div className="ml-1 flex-1 justify-between">
                <p className="text-sm">Learn about the DataOps.live IDE</p>
              </div>
            </button>
          </a>
        </div>
        <p className="pt-4 text-sm text-gray-600 text-center">
          You&apos;re now ready to leverage our Cloud IDE to build and test your dbt models. Click the button below to
          continue your journey with DataOps.live and take full advantage of our platform&apos;s capabilities.
        </p>
        <div className="w-full pt-4 flex justify-center space-x-2">
          <a href={devReadyLink}>
            <button className="w-[12rem] rounded-md bg-dataops-secondary-blue px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-hover-secondary-blue disabled:bg-gray-400">
              Open Develop
            </button>
          </a>
          <a href={dataOpsProjectLink} className="w-[12rem]">
            <button className="w-[12rem] rounded-md bg-white py-2 px-3 text-sm text-gray-900 font-semibold shadow-sm hover:bg-gray-200 ring-1 ring-gray-300">
              Open Project
            </button>
          </a>
        </div>
        <div className="absolute bottom-5 right-6 ml-[auto]">
          <button
            type="button"
            onClick={() => dispatch(setCurrentStep(numberOfSteps - 1))}
            className="text-sm leading-6 text-gray-900 hover:underline"
          >
            Back
          </button>
        </div>
      </div>
    </div>
  );
}
