import React, { useState } from 'react';
import { useGenerateDbtJobsMutation } from 'app/createApi';
import { useAppSelector, useAppDispatch } from 'app/hooks';
import { selectSnowflakeAccount, selectSelectedDatabase, setSelectedDatabase } from 'app/snowflakeSlice';
import { selectUseSampleProject, selectDbtVersion } from '../reducers/dbtMigrationSlice';
import { selectProjectPath, selectBranch } from 'app/sharedSlice';
import StepWrapper from 'components/StepWrapper';
import LoadingAndErrorSection from 'components/LoadingAndErrorSection';
import DatabaseSelector from 'components/DatabaseSelector';

export interface TargetDatabaseStepProps {
  onBack: () => void;
  onContinue: () => void;
}

export default function TargetDatabaseStep(props: TargetDatabaseStepProps): JSX.Element {
  const dispatch = useAppDispatch();
  const selectedDatabase = useAppSelector(selectSelectedDatabase);
  const snowflakeAccount = useAppSelector(selectSnowflakeAccount);
  const projectPath = useAppSelector(selectProjectPath);
  const branch = useAppSelector(selectBranch);

  const useSampleProject = useAppSelector(selectUseSampleProject);
  const dbtVersion = useAppSelector(selectDbtVersion);

  const [loading, setLoading] = useState<boolean>(false);
  const [failed, setFailed] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string>('Failed to generate dbt jobs');

  const [generateDbtJobs] = useGenerateDbtJobsMutation();

  const onContinue = (): void => {
    setLoading(true);
    setFailed(false);

    generateDbtJobs({
      projectName: projectPath,
      masterDatabase: selectedDatabase,
      account: snowflakeAccount,
      dbtVersion: useSampleProject ? '1.7' : dbtVersion,
      branch,
      jobList: ['BUILD'],
      seedSelector: '*',
      sourceSelector: '*',
      modelSelector: '*',
    })
      .unwrap()
      .then((res: any) => {
        console.log(res);
        props.onContinue();
        // pipeline({ dataProductName: 'full', projectName: projectPath, branch, mergeMain: false, camelCase: false })
        //   .unwrap()
        //   .then((res: any) => {
        //     dispatch(setPipelineId(res));
        //     props.onContinue();
        //   })
        //   .catch((err) => {
        //     console.log(err);
        //     setFailed(true);
        //     setErrorMessage(err.data);
        //   })
        //   .finally(() => {
        //     setLoading(false);
        //   });
      })
      .catch((err) => {
        console.log(err);
        setFailed(true);
        setErrorMessage(err.data);
        setLoading(false);
      });
  };

  return (
    <StepWrapper
      title="Target database"
      subtitle="Select the target database"
      onBack={() => props.onBack()}
      onContinue={() => onContinue()}
      isLoading={loading}
    >
      <div className="min-h-full mb-6 relative">
        <DatabaseSelector
          onSelect={(database) => {
            dispatch(setSelectedDatabase(database));
          }}
          selectedDatabases={selectedDatabase !== '' ? [selectedDatabase] : []}
        />
      </div>
      <div className="absolute w-[calc(100%-4rem)] bottom-20">
        <div className="w-3/4 wide:w-1/2 mx-[auto]">
          <LoadingAndErrorSection
            isLoading={loading}
            isFailed={failed}
            errorMessage={errorMessage}
            hideLoading={true}
          />
        </div>
      </div>
    </StepWrapper>
  );
}
