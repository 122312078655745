import React, { useState, useRef } from 'react';
import { useCreateDbtMigrationProjectMutation } from 'app/createApi';
import { useAppSelector, useAppDispatch } from 'app/hooks';
import {
  selectSelectedGroup,
  selectCreateNewProject,
  selectNewProjectName,
  selectSelectedProject,
  selectProjectsCreatedInCurrentSession,
  setProjectPath,
  setBranch,
  setProjectsCreatedInCurrentSession,
} from 'app/sharedSlice';
import {
  selectSnowflakeAccountLabel,
  selectSnowflakeUsername,
  selectSnowflakeWarehouse,
  selectSnowflakeRole,
  selectSnowflakeAccountConnected,
  selectSnowflakePrivateKey,
  selectSnowflakePassphrase,
  setSnowflakeUsername,
  setSnowflakeWarehouse,
  setSnowflakeRole,
  setSnowflakeAccountConnected,
  setSnowflakePrivateKey,
  setSnowflakePassphrase,
  setSnowflakeAccountLabel,
} from 'app/snowflakeSlice';
import { selectIsProjectNameFromUrl } from '../reducers/dbtMigrationSlice';
import StepWrapper from 'components/StepWrapper';
import GroupAndProjectSelector from 'components/GroupAndProjectSelector';
import SnowflakeAccountForm from 'components/SnowflakeAccountForm';
import LoadingAndErrorSection from 'components/LoadingAndErrorSection';
import { isProjectNameValid } from 'utils/formatUtils';

export interface TargetProjectStepProps {
  onBack: () => void;
  onContinue: () => void;
}

export default function TargetProjectStep(props: TargetProjectStepProps): JSX.Element {
  const dispatch = useAppDispatch();
  const [loading, setLoading] = useState<boolean>(false);
  const [failed, setFailed] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string>('Failed to generate config');
  const [createDbtMigrationProject] = useCreateDbtMigrationProjectMutation();

  const selectedGroup = useAppSelector(selectSelectedGroup);
  const createNewProject = useAppSelector(selectCreateNewProject);
  const newProjectName = useAppSelector(selectNewProjectName);
  const selectedProject = useAppSelector(selectSelectedProject);
  const projectsCreatedInCurrentSession = useAppSelector(selectProjectsCreatedInCurrentSession);
  const snowflakeAccountLabel = useAppSelector(selectSnowflakeAccountLabel);
  const snowflakeUsername = useAppSelector(selectSnowflakeUsername);
  const snowflakeWarehouse = useAppSelector(selectSnowflakeWarehouse);
  const snowflakeRole = useAppSelector(selectSnowflakeRole);
  const snowflakePrivateKey = useAppSelector(selectSnowflakePrivateKey);
  const snowflakePassphrase = useAppSelector(selectSnowflakePassphrase);
  const snowflakeAccountConnected = useAppSelector(selectSnowflakeAccountConnected);

  const isProjectNameFromUrl = useAppSelector(selectIsProjectNameFromUrl);

  const formRef = useRef<HTMLFormElement>(null);

  const onContinue = (): void => {
    if (formRef.current?.reportValidity() === true) {
      setLoading(true);
      setFailed(false);
      let projectName = '';
      if (createNewProject) {
        if (newProjectName === '') return;

        if (!isProjectNameValid(newProjectName)) {
          setFailed(true);
          setErrorMessage(
            'The project name is invalid. Project names must start and end with a letter or digit, avoid consecutive special characters, and include only letters, digits, underscores, dots, or dashes.',
          );
          setLoading(false);
          return;
        }

        const newProjectPath = `${selectedGroup.path}/${newProjectName}`;
        console.log('New project path:', newProjectPath);
        dispatch(setProjectPath(newProjectPath));
        projectName = newProjectPath;
      } else {
        if (selectedProject.name === '') return;
        console.log('Selected project: ', selectedProject);
        dispatch(setProjectPath(selectedProject.path));
        projectName = selectedProject.path;
      }

      const projectAlredyCreatedInThisSession = projectsCreatedInCurrentSession.includes(projectName);
      const isNewProject = createNewProject && !projectAlredyCreatedInThisSession;

      createDbtMigrationProject({
        projectName,
        isNewProject,
      })
        .unwrap()
        .then((res: any) => {
          dispatch(setBranch(res));
          dispatch(setProjectsCreatedInCurrentSession([...projectsCreatedInCurrentSession, projectName]));
          props.onContinue();
        })
        .catch((err) => {
          console.log(err);
          setFailed(true);
          setErrorMessage(err.data);
        })
        .finally(() => {
          setLoading(false);
        });
    }
  };

  return (
    <StepWrapper
      title="Target project"
      subtitle="Select target project and provide your snowflake account details"
      onBack={() => props.onBack()}
      onContinue={() => onContinue()}
      continueDisabled={
        loading ||
        selectedGroup === null ||
        selectedGroup.name === '' ||
        (createNewProject && newProjectName === '') ||
        (!createNewProject && selectedProject !== null && selectedProject.name === '') ||
        !snowflakeAccountConnected
      }
      isLoading={loading}
    >
      <div className="h-full">
        <form ref={formRef}>
          <div className="mx-[auto] w-3/4 pb-2 grid grid-cols-2 gap-6">
            <GroupAndProjectSelector showCreateNewProject={!isProjectNameFromUrl} />
          </div>
        </form>

        <div className="w-4/5 mx-[auto] border-t border-gray-300 my-8"></div>

        <div className="w-3/4 mx-[auto] pb-4">
          <div className="mx-[auto] text-gray-600 text-sm mb-2 w-3/4 text-center">
            Connect to your target Snowflake account
          </div>
          <SnowflakeAccountForm
            snowflakeAccount={snowflakeAccountLabel}
            setSnowflakeAccount={(account) => dispatch(setSnowflakeAccountLabel(account))}
            snowflakeUsername={snowflakeUsername}
            setSnowflakeUsername={(username) => dispatch(setSnowflakeUsername(username))}
            snowflakeWarehouse={snowflakeWarehouse}
            setSnowflakeWarehouse={(warehouse) => dispatch(setSnowflakeWarehouse(warehouse))}
            snowflakeRole={snowflakeRole}
            setSnowflakeRole={(role) => dispatch(setSnowflakeRole(role))}
            snowflakeAccountConnected={snowflakeAccountConnected}
            setSnowflakeAccountConnected={(connected) => dispatch(setSnowflakeAccountConnected(connected))}
            snowflakePrivateKey={snowflakePrivateKey}
            setSnowflakePrivateKey={(key) => dispatch(setSnowflakePrivateKey(key))}
            snowflakePassphrase={snowflakePassphrase}
            setSnowflakePassphrase={(passphrase) => dispatch(setSnowflakePassphrase(passphrase))}
          />
        </div>

        <div className="w-3/4 mx-[auto]">
          <LoadingAndErrorSection
            isLoading={loading}
            isFailed={failed}
            errorMessage={errorMessage}
            hideLoading={true}
          />
        </div>
      </div>
    </StepWrapper>
  );
}
