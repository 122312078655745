import React, { useState, useEffect } from 'react';
import { InformationCircleIcon } from '@heroicons/react/24/outline';
import { useGetModelsFileContentQuery, useSetModelsFileContentMutation } from 'app/createApi';
import { useAppSelector } from 'app/hooks';
import { selectProjectPath, selectBranch } from 'app/sharedSlice';
import { selectName } from '../reducers/astraZenecaSlice';
import { cloneDeep } from 'lodash';
import StepWrapper from 'components/StepWrapper';
import TestEditor from 'features/astra-zeneca-flow/components/TestEditor';
import AddTestDialog from 'features/astra-zeneca-flow/components/AddTestDialog';
import LoadingAndErrorSection from 'components/LoadingAndErrorSection';
import type {
  IModelsFileContent,
  ITest,
  IDbtUtilsUniqueCombinationOfColumnsTest,
  IModelAndColumn,
} from '../types/testEditorTypes';

export interface DatabaseTablesStepProps {
  onBack: () => void;
  onContinue: () => void;
}

export default function TestEditorStep(props: DatabaseTablesStepProps): JSX.Element {
  const [processedVirtualizedModelsFileContent, setProcessedVirtualiedModelsFileContent] =
    useState<IModelsFileContent>();
  const [processedMaterializedModelsFileContent, setProcessedMaterializedModelsFileContent] =
    useState<IModelsFileContent>();
  const [processedIncrementalModelsFileContent, setProcessedIncrementalModelsFileContent] =
    useState<IModelsFileContent>();

  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [selectedModelsAndColumns, setSelectedModelsAndColumns] = useState<IModelAndColumn[]>([]);
  const [existingTest, setExistingTest] = useState<ITest | undefined>();

  const [loading, setLoading] = useState<boolean>(false);
  const [failed, setFailed] = useState<boolean>(false);
  const [errorMessage] = useState<string>(
    'Oops! Failed to create tests. Please try again or contact support@dataops.live if the issue persists.',
  );

  const projectPath = useAppSelector(selectProjectPath);
  const branch = useAppSelector(selectBranch);
  const dataProductName = useAppSelector(selectName);

  const { data: virtualizedModelsFileContent, isLoading: isVirtualizedModelsLoading } = useGetModelsFileContentQuery({
    projectPath,
    branch,
    dataProductName,
    materialization: 'virtualized',
  });

  const { data: materializedModelsFileContent, isLoading: isMaterializedModelsLoading } = useGetModelsFileContentQuery({
    projectPath,
    branch,
    dataProductName,
    materialization: 'materialized',
  });

  const { data: incrementalModelsFileContent, isLoading: isIncrementaldModelsLoading } = useGetModelsFileContentQuery({
    projectPath,
    branch,
    dataProductName,
    materialization: 'incremental',
  });

  const [setModelsFileContent] = useSetModelsFileContentMutation();

  useEffect(() => {
    const tmpVirtualizedModelsFileContent = cloneDeep(virtualizedModelsFileContent);

    for (const model of tmpVirtualizedModelsFileContent?.models ?? []) {
      for (const uniqueCombinationTest of model.tests ?? []) {
        for (const uniqueCombinationTestColumn of uniqueCombinationTest['dbt_utils.unique_combination_of_columns']
          .combination_of_columns) {
          for (const column of model.columns) {
            if (column.name === uniqueCombinationTestColumn) {
              column.tests.push(uniqueCombinationTest);
            }
          }
        }
      }
    }

    for (const model of tmpVirtualizedModelsFileContent?.models ?? []) {
      for (const column of model.columns) {
        if (column.description === 'Primary key') {
          column.description = '';
          column.tests.push('primary_key');
          column.tests = column.tests.filter((test) => test !== 'unique' && test !== 'not_null');
        }
      }
    }

    setProcessedVirtualiedModelsFileContent(tmpVirtualizedModelsFileContent);
  }, [virtualizedModelsFileContent]);

  useEffect(() => {
    const tmpMaterializedModelsFileContent = cloneDeep(materializedModelsFileContent);

    for (const model of tmpMaterializedModelsFileContent?.models ?? []) {
      for (const uniqueCombinationTest of model.tests ?? []) {
        for (const uniqueCombinationTestColumn of uniqueCombinationTest['dbt_utils.unique_combination_of_columns']
          .combination_of_columns) {
          for (const column of model.columns) {
            if (column.name === uniqueCombinationTestColumn) {
              column.tests.push(uniqueCombinationTest);
            }
          }
        }
      }
    }

    for (const model of tmpMaterializedModelsFileContent?.models ?? []) {
      for (const column of model.columns) {
        if (column.description === 'Primary key') {
          column.description = '';
          column.tests.push('primary_key');
          column.tests = column.tests.filter((test) => test !== 'unique' && test !== 'not_null');
        }
      }
    }

    setProcessedMaterializedModelsFileContent(tmpMaterializedModelsFileContent);
  }, [materializedModelsFileContent]);

  useEffect(() => {
    const tmpIncrementalModelsFileContent = cloneDeep(incrementalModelsFileContent);

    for (const model of tmpIncrementalModelsFileContent?.models ?? []) {
      for (const uniqueCombinationTest of model.tests ?? []) {
        for (const uniqueCombinationTestColumn of uniqueCombinationTest['dbt_utils.unique_combination_of_columns']
          .combination_of_columns) {
          for (const column of model.columns) {
            if (column.name === uniqueCombinationTestColumn) {
              column.tests.push(uniqueCombinationTest);
            }
          }
        }
      }
    }

    for (const model of tmpIncrementalModelsFileContent?.models ?? []) {
      for (const column of model.columns) {
        if (column.description === 'Primary key') {
          column.description = '';
          column.tests.push('primary_key');
          column.tests = column.tests.filter((test) => test !== 'unique' && test !== 'not_null');
        }
      }
    }

    setProcessedIncrementalModelsFileContent(tmpIncrementalModelsFileContent);
  }, [incrementalModelsFileContent]);

  const onAddTest = (newTest: ITest, existingTest: ITest | undefined): string => {
    let updatedMaterializedModelsFileContent =
      processedMaterializedModelsFileContent !== undefined
        ? cloneDeep(processedMaterializedModelsFileContent)
        : undefined;
    let updatedVirtualizedModelsFileContent =
      processedVirtualizedModelsFileContent !== undefined
        ? cloneDeep(processedVirtualizedModelsFileContent)
        : undefined;
    let updatedIncrementalModelsFileContent =
      processedIncrementalModelsFileContent !== undefined
        ? cloneDeep(processedIncrementalModelsFileContent)
        : undefined;

    if (existingTest !== undefined) {
      for (const selectedModelAndColumn of selectedModelsAndColumns) {
        if (selectedModelAndColumn.modelName.startsWith('m_') && updatedMaterializedModelsFileContent !== undefined) {
          updatedMaterializedModelsFileContent =
            removeTest(
              updatedMaterializedModelsFileContent,
              selectedModelAndColumn.modelName,
              selectedModelAndColumn.columnName,
              existingTest,
            ) ?? updatedMaterializedModelsFileContent;
        } else if (
          selectedModelAndColumn.modelName.startsWith('v_') &&
          updatedVirtualizedModelsFileContent !== undefined
        ) {
          updatedVirtualizedModelsFileContent =
            removeTest(
              updatedVirtualizedModelsFileContent,
              selectedModelAndColumn.modelName,
              selectedModelAndColumn.columnName,
              existingTest,
            ) ?? updatedVirtualizedModelsFileContent;
        } else if (
          selectedModelAndColumn.modelName.startsWith('i_') &&
          updatedIncrementalModelsFileContent !== undefined
        ) {
          updatedIncrementalModelsFileContent =
            removeTest(
              updatedIncrementalModelsFileContent,
              selectedModelAndColumn.modelName,
              selectedModelAndColumn.columnName,
              existingTest,
            ) ?? updatedIncrementalModelsFileContent;
        }
      }
    }

    if ((newTest as IDbtUtilsUniqueCombinationOfColumnsTest)['dbt_utils.unique_combination_of_columns'] === undefined) {
      for (const selectedModelAndColumn of selectedModelsAndColumns) {
        const updatedModelsFileContent = selectedModelAndColumn.modelName.startsWith('m_')
          ? updatedMaterializedModelsFileContent
          : selectedModelAndColumn.modelName.startsWith('v_')
          ? updatedVirtualizedModelsFileContent
          : updatedIncrementalModelsFileContent;
        if (updatedModelsFileContent === undefined) {
          return 'Failed to add test';
        }
        for (const model of updatedModelsFileContent.models) {
          if (model.name === selectedModelAndColumn.modelName) {
            for (const column of model.columns) {
              if (column.name === selectedModelAndColumn.columnName) {
                if (column.tests === undefined) {
                  column.tests = [];
                }
                if (column.tests.some((t: ITest) => JSON.stringify(t) === JSON.stringify(newTest))) {
                  return 'Test already exists';
                }
                if ((newTest === 'unique' || newTest === 'not_null') && column.tests.includes('primary_key')) {
                  return 'There is already a primary key test';
                }
                if (newTest === 'primary_key') {
                  column.tests = column.tests.filter((t: ITest) => t !== 'unique' && t !== 'not_null');
                }
                column.tests.push(newTest);
              }
            }
          }
        }
      }
    } else {
      for (const selectedModelAndColum of selectedModelsAndColumns) {
        const updatedModelsFileContent = selectedModelAndColum.modelName.startsWith('m_')
          ? updatedMaterializedModelsFileContent
          : selectedModelAndColum.modelName.startsWith('v_')
          ? updatedVirtualizedModelsFileContent
          : updatedIncrementalModelsFileContent;
        if (updatedModelsFileContent === undefined) {
          return 'Failed to add test';
        }
        for (const model of updatedModelsFileContent.models) {
          if (model.name === selectedModelAndColum.modelName) {
            for (const uniqueCombinationTestColumn of (newTest as IDbtUtilsUniqueCombinationOfColumnsTest)[
              'dbt_utils.unique_combination_of_columns'
            ].combination_of_columns) {
              for (const column of model.columns) {
                if (column.name === uniqueCombinationTestColumn) {
                  if (column.tests === undefined) {
                    column.tests = [];
                  }
                  column.tests.push(newTest);
                }
              }
            }

            if (model.tests === undefined) {
              model.tests = [];
            }

            model.tests.push(newTest as IDbtUtilsUniqueCombinationOfColumnsTest);
          }
        }
      }
    }

    if (updatedMaterializedModelsFileContent !== undefined) {
      setProcessedMaterializedModelsFileContent(updatedMaterializedModelsFileContent);
    }
    if (updatedVirtualizedModelsFileContent !== undefined) {
      setProcessedVirtualiedModelsFileContent(updatedVirtualizedModelsFileContent);
    }
    if (updatedIncrementalModelsFileContent !== undefined) {
      setProcessedIncrementalModelsFileContent(updatedIncrementalModelsFileContent);
    }

    return 'success';
  };

  const onRemoveTest = (modelName: string, columnName: string, test: ITest): void => {
    if (modelName.startsWith('m_') && processedMaterializedModelsFileContent !== undefined) {
      const updatedMaterializedModelsFileContent = removeTest(
        processedMaterializedModelsFileContent,
        modelName,
        columnName,
        test,
      );
      setProcessedMaterializedModelsFileContent(updatedMaterializedModelsFileContent);
    } else if (modelName.startsWith('v_') && processedVirtualizedModelsFileContent !== undefined) {
      const updatedVirtualizedModelsFileContent = removeTest(
        processedVirtualizedModelsFileContent,
        modelName,
        columnName,
        test,
      );
      setProcessedVirtualiedModelsFileContent(updatedVirtualizedModelsFileContent);
    } else if (modelName.startsWith('i_') && processedIncrementalModelsFileContent !== undefined) {
      const updatedIncrementalModelsFileContent = removeTest(
        processedIncrementalModelsFileContent,
        modelName,
        columnName,
        test,
      );
      setProcessedIncrementalModelsFileContent(updatedIncrementalModelsFileContent);
    }
  };

  const removeTest = (
    modelsFileContent: IModelsFileContent,
    modelName: string,
    columnName: string,
    test: ITest,
  ): IModelsFileContent | undefined => {
    if (modelsFileContent === undefined) {
      return modelsFileContent;
    }

    const updatedModelsFileContent = cloneDeep(modelsFileContent);

    if ((test as IDbtUtilsUniqueCombinationOfColumnsTest)['dbt_utils.unique_combination_of_columns'] === undefined) {
      for (const model of updatedModelsFileContent.models) {
        if (model.name === modelName) {
          for (const column of model.columns) {
            if (column.name === columnName) {
              const index = column.tests.findIndex((t) => JSON.stringify(t) === JSON.stringify(test));
              if (index > -1) {
                column.tests.splice(index, 1);
              }
            }
          }
        }
      }
    } else {
      for (const model of updatedModelsFileContent.models) {
        if (model.name === modelName) {
          for (const uniqueCombinationTestColumn of (test as IDbtUtilsUniqueCombinationOfColumnsTest)[
            'dbt_utils.unique_combination_of_columns'
          ].combination_of_columns) {
            for (const column of model.columns) {
              if (column.name === uniqueCombinationTestColumn) {
                const index = column.tests.findIndex((t) => JSON.stringify(t) === JSON.stringify(test));
                if (index > -1) {
                  column.tests.splice(index, 1);
                }
              }
            }
          }

          const index = model.tests.findIndex((t) => JSON.stringify(t) === JSON.stringify(test));
          if (index > -1) {
            model.tests.splice(index, 1);
          }
        }
      }
    }

    return updatedModelsFileContent;
  };

  const onContinue = async (): Promise<void> => {
    setLoading(true);
    setFailed(false);

    for (const processedModelsFileContent of [
      processedMaterializedModelsFileContent,
      processedVirtualizedModelsFileContent,
      processedIncrementalModelsFileContent,
    ]) {
      if (processedModelsFileContent === undefined || processedModelsFileContent.models === undefined) {
        continue;
      }

      const preparedModelsFileContent: IModelsFileContent = cloneDeep(processedModelsFileContent);
      for (const model of preparedModelsFileContent.models) {
        for (const column of model.columns) {
          if (column.tests === undefined) {
            continue;
          }

          column.tests = column.tests.filter(
            (test) =>
              (test as IDbtUtilsUniqueCombinationOfColumnsTest)['dbt_utils.unique_combination_of_columns'] ===
              undefined,
          );

          column.tests = column.tests.flatMap((test) => {
            if (test === 'primary_key') {
              column.description = 'Primary key';
              return ['unique', 'not_null'];
            }

            return test;
          });
        }
      }

      try {
        await setModelsFileContent({
          projectPath,
          branch,
          dataProductName,
          modelsFileContent: preparedModelsFileContent,
          materialization:
            processedModelsFileContent === processedMaterializedModelsFileContent
              ? 'materialized'
              : processedModelsFileContent === processedVirtualizedModelsFileContent
              ? 'virtualized'
              : 'incremental',
        }).unwrap();
      } catch (err) {
        console.log(err);
        setFailed(true);
        setLoading(false);
        return;
      }
    }
    setFailed(false);
    setLoading(false);
    props.onContinue();
  };

  return (
    <StepWrapper
      title="Test Editor"
      subtitle="Add tests to your models"
      onBack={() => props.onBack()}
      onContinue={() => {
        onContinue().catch((err) => console.log(err));
      }}
      isLoading={loading}
    >
      <div className="w-full max-w-[40rem] mx-[auto] flex flex-col items-center">
        <div className="w-full h-[1.5rem] max-w-[35rem] mb-2 flex items-stretch justify-end">
          {selectedModelsAndColumns.length > 0 && (
            <>
              <div className="text-gray-500 text-sm">{selectedModelsAndColumns.length} columns selected</div>
              <button
                className="ml-[auto] rounded-full bg-dataops-secondary-blue px-2 py-0.5 text-sm font-semibold text-white shadow-sm hover:bg-hover-secondary-blue"
                onClick={() => {
                  setExistingTest(undefined);
                  setIsDialogOpen(true);
                }}
              >
                Add test
              </button>
              <button
                className="rounded-full ml-1 px-2 py-0.5 text-sm font-semibold text-dataops-secondary-blue shadow-sm border border-dataops-secondary-blue hover:border-hover-secondary-blue hover:text-hover-secondary-blue"
                onClick={() => {
                  setSelectedModelsAndColumns([]);
                }}
              >
                Clear selection
              </button>
            </>
          )}
        </div>
        {[
          processedMaterializedModelsFileContent,
          processedVirtualizedModelsFileContent,
          processedIncrementalModelsFileContent,
        ].map((processedModelsFileContent, index) => (
          <TestEditor
            key={index}
            modelsFileContent={processedModelsFileContent}
            openDialog={setIsDialogOpen}
            selectedModelsAndColumns={selectedModelsAndColumns}
            setSelectedModelsAndColumns={setSelectedModelsAndColumns}
            setExistingTest={setExistingTest}
            onRemoveTest={onRemoveTest}
          />
        ))}
        <AddTestDialog
          modelsFileContent={
            selectedModelsAndColumns.length > 0
              ? selectedModelsAndColumns[0].modelName.startsWith('m_')
                ? processedMaterializedModelsFileContent
                : selectedModelsAndColumns[0].modelName.startsWith('v_')
                ? processedVirtualizedModelsFileContent
                : processedIncrementalModelsFileContent
              : undefined
          }
          open={isDialogOpen}
          setOpen={setIsDialogOpen}
          selectedModelsAndColumns={selectedModelsAndColumns}
          setSelectedModelsAndColumns={setSelectedModelsAndColumns}
          existingTest={existingTest}
          setExistingTest={setExistingTest}
          onAddTest={onAddTest}
        />
        {processedMaterializedModelsFileContent === undefined &&
          processedVirtualizedModelsFileContent === undefined &&
          processedIncrementalModelsFileContent === undefined && (
            <div className="min-h-8 flex items-center justify-center">
              <div
                className="animate-spin inline-block w-8 h-8 border-[2px] border-current border-t-transparent text-cyan-800 rounded-full"
                role="status"
                aria-label="loading"
              >
                <span className="sr-only">Loading...</span>
              </div>
            </div>
          )}
        {!isMaterializedModelsLoading &&
          !isVirtualizedModelsLoading &&
          !isIncrementaldModelsLoading &&
          (processedMaterializedModelsFileContent === undefined ||
            processedMaterializedModelsFileContent.models === undefined ||
            processedMaterializedModelsFileContent.models.length === 0) &&
          (processedVirtualizedModelsFileContent === undefined ||
            processedVirtualizedModelsFileContent.models === undefined ||
            processedVirtualizedModelsFileContent.models.length === 0) &&
          (processedIncrementalModelsFileContent === undefined ||
            processedIncrementalModelsFileContent.models === undefined ||
            processedIncrementalModelsFileContent.models.length === 0) && (
            <div className="max-w-[50rem] mt-2 mx-[auto] rounded-md bg-blue-50 p-4 flex">
              <div className="flex-shrink-0">
                <InformationCircleIcon className="h-5 w-5 text-blue-400" aria-hidden="true" />
              </div>
              <div>
                <div className="ml-3 flex-1 md:flex md:justify-between">
                  <p className="text-sm text-blue-700 font-semibold">Heads up</p>
                </div>
                <div className="ml-3 flex-1 md:flex md:justify-between">
                  <p className="text-sm text-blue-700">
                    Currently, your data product does not include any models. Please navigate to the SQL Model Builder
                    step to add and configure models.
                  </p>
                </div>
              </div>
            </div>
          )}
        <LoadingAndErrorSection isLoading={loading} isFailed={failed} errorMessage={errorMessage} hideLoading={true} />
      </div>
    </StepWrapper>
  );
}
