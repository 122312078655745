import React, { useRef, Fragment, useState, useEffect } from 'react';
import { Dialog, DialogPanel, DialogTitle, Description, Transition } from '@headlessui/react';
import DropdownSingleselect from 'components/DropdownSingleselect';
import DropdownMultiselect from '../components/DropdownMultiselect';
import type { ITest, IModelsFileContent, IModelAndColumn } from '../types/testEditorTypes';
import InformationTooltip from 'components/InformationTooltip';

interface AddTestDialogProps {
  modelsFileContent: IModelsFileContent | undefined;
  open: boolean;
  setOpen: (open: boolean) => void;
  selectedModelsAndColumns: IModelAndColumn[];
  setSelectedModelsAndColumns: (value: IModelAndColumn[]) => void;
  existingTest: ITest | undefined;
  setExistingTest: (value: ITest | undefined) => void;
  onAddTest: (newTest: ITest, existingTest: ITest | undefined) => string;
}

type ITestType =
  | 'unique'
  | 'not_null'
  | 'primary_key'
  | 'accepted_values'
  | 'relationships'
  | 'unique_combination_of_columns';
const testTypes = [
  'unique',
  'not_null',
  'primary_key',
  'accepted_values',
  'relationships',
  'unique_combination_of_columns',
];

const tooltipDescriptions = {
  unique: 'This test ensures that each value in the column is unique across all rows.',
  not_null: 'This test ensures that no null values are allowed in the selected column.',
  primary_key: 'This test ensures that the selected column is the primary key.',
  accepted_values:
    "Define the specific values that are considered valid for this column. Use this test to ensure data consistency by restricting the column's values to the accepted set.",
  relationships:
    'This test validates that values in the column match related values in another table or model, ensuring referential integrity.',
  unique_combination_of_columns:
    'This test ensures that the selected columns form a unique combination across all rows.',
};

const AddTestDialog = (props: AddTestDialogProps): JSX.Element => {
  const cancelButtonRef = useRef(null);

  const [selectedTestType, setSelectedTestType] = useState<ITestType | undefined>();
  const [acceptedValues, setAcceptedValues] = useState('');
  const [relationField, setRelationField] = useState('');
  const [relationshipToValue, setRelationshipToValue] = useState('');
  const [uniqueCombinationOfColumns, setUniqueCombinationOfColumns] = useState<string[]>([]);
  const [columnsInModel, setColumnsInModel] = useState<string[]>([]);

  const [showSuccessMessage, setShowSuccessMessage] = useState(false);
  const [successMessage, setSuccessMessage] = useState('');
  const [showErrorMessage, setShowErrorMessage] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [showSummary, setShowSummary] = useState(false);
  const [summary, setSummary] = useState('');

  useEffect(() => {
    if (props.existingTest === undefined) {
      setAcceptedValues('');
      setRelationField('');
      setRelationshipToValue('');
      setUniqueCombinationOfColumns([]);
      return;
    }

    if (typeof props.existingTest === 'string' && props.existingTest === 'unique') {
      setSelectedTestType('unique');
    } else if (typeof props.existingTest === 'string' && props.existingTest === 'not_null') {
      setSelectedTestType('not_null');
    } else if (typeof props.existingTest === 'string' && props.existingTest === 'primary_key') {
      setSelectedTestType('primary_key');
    } else if (
      (props.existingTest as any).accepted_values !== undefined &&
      (props.existingTest as any).accepted_values.values !== undefined
    ) {
      setSelectedTestType('accepted_values');
      setAcceptedValues((props.existingTest as any).accepted_values.values.join(','));
    } else if ((props.existingTest as any).relationships !== undefined) {
      setSelectedTestType('relationships');
      setRelationField((props.existingTest as any).relationships.field);
      setRelationshipToValue((props.existingTest as any).relationships.to);
    } else if ((props.existingTest as any)['dbt_utils.unique_combination_of_columns'] !== undefined) {
      setSelectedTestType('unique_combination_of_columns');
      setUniqueCombinationOfColumns(
        (props.existingTest as any)['dbt_utils.unique_combination_of_columns'].combination_of_columns,
      );
    }
  }, [props.existingTest, props.selectedModelsAndColumns]);

  useEffect(() => {
    if (selectedTestType !== undefined) {
      const columnsString = props.selectedModelsAndColumns
        .map((modelAndColumn) => modelAndColumn.columnName)
        .join(', ');
      const columnsLabel = props.selectedModelsAndColumns.length > 1 ? 'columns' : 'column';

      switch (selectedTestType) {
        case 'unique':
          setSummary(`You are adding an 'unique' test for the ${columnsString} ${columnsLabel}.`);
          break;
        case 'not_null':
          setSummary(`You are adding an 'not_null' test for the ${columnsString} ${columnsLabel}.`);
          break;
        case 'primary_key':
          setSummary(`You are setting the ${columnsString} ${columnsLabel} as the primary key.`);
          break;
        case 'accepted_values':
          setSummary(
            `You are adding an 'accepted_values' test for the ${columnsString} ${columnsLabel} with the following values: ${acceptedValues}`,
          );
          break;
        case 'relationships':
          setSummary(`You are adding an 'relationships' test for the ${columnsString} ${columnsLabel}.`);
          break;
        case 'unique_combination_of_columns':
          setSummary(
            `You are adding an 'unique_combination' test for the following columns: ${uniqueCombinationOfColumns.join(
              ', ',
            )}`,
          );
          break;
        default:
          setSummary('');
          break;
      }
      setShowSummary(true);
    } else {
      setShowSummary(false);
    }
  }, [selectedTestType, acceptedValues, uniqueCombinationOfColumns]);

  useEffect(() => {
    if (props.selectedModelsAndColumns.length === 1) {
      for (const model of props.modelsFileContent?.models ?? []) {
        if (model.name === props.selectedModelsAndColumns[0].modelName) {
          setColumnsInModel(model.columns.map((column) => column.name));
        }
      }
    }
  }, [props.modelsFileContent, props.selectedModelsAndColumns]);

  // If the test type changes, hide the error message
  useEffect(() => {
    setShowSuccessMessage(false);
    setSuccessMessage('');
    setShowErrorMessage(false);
    setErrorMessage('');
  }, [selectedTestType]);

  const onAddTest = (): void => {
    let newTest: ITest = '';
    if (selectedTestType === 'unique') {
      newTest = 'unique';
      setSuccessMessage('Unique test added successfully');
    } else if (selectedTestType === 'not_null') {
      newTest = 'not_null';
      setSuccessMessage('Not null test added successfully');
    } else if (selectedTestType === 'primary_key') {
      newTest = 'primary_key';
      setSuccessMessage(' The primary key has been successfully set');
    } else if (selectedTestType === 'accepted_values') {
      newTest = { accepted_values: { values: acceptedValues.split(',') } };
      setSuccessMessage('Accepted values test added successfully');
    } else if (selectedTestType === 'relationships') {
      newTest = { relationships: { field: relationField, to: relationshipToValue } };
      setSuccessMessage('Relationships test added successfully');
    } else if (selectedTestType === 'unique_combination_of_columns') {
      newTest = { 'dbt_utils.unique_combination_of_columns': { combination_of_columns: uniqueCombinationOfColumns } };
      setSuccessMessage('Unique combination of columns test added successfully');
    }

    const returnResult = props.onAddTest(newTest, props.existingTest);
    if (returnResult !== 'success') {
      setShowErrorMessage(true);
      setErrorMessage(returnResult);
    } else {
      setShowSuccessMessage(true);
    }
  };

  const onClose = (): void => {
    props.setOpen(false);
    props.setSelectedModelsAndColumns([]);
    props.setExistingTest(undefined);
    setShowSuccessMessage(false);
    setShowErrorMessage(false);
    setShowSummary(false);
    setSelectedTestType(undefined);
  };

  const getTooltipMessage = (): string | undefined => {
    if (!testTypes.includes(selectedTestType ?? '')) {
      return 'Please select a test type';
    }
    if (selectedTestType === 'accepted_values' && acceptedValues === '') {
      return 'Please provide accepted values';
    }
    if (selectedTestType === 'relationships' && (relationField === '' || relationshipToValue === '')) {
      return 'Please provide the To and Relation column values';
    }
    if (selectedTestType === 'unique_combination_of_columns' && uniqueCombinationOfColumns.length < 2) {
      return 'Please select at least two columns for unique combination';
    }
    return undefined;
  };

  return (
    <Transition.Root show={props.open} as={Fragment}>
      <Dialog as="div" className="relative z-10" initialFocus={cancelButtonRef} onClose={onClose}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 overflow-y-auto">
          <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <DialogPanel className="w-[22rem] relative transform overflow-visible rounded-lg bg-white px-4 pt-5 pb-4 text-left shadow-xl transition-all sm:my-8 sm:p-6">
                <div className="text-left">
                  <DialogTitle as="h3" className="text-xl text-center font-semibold leading-6 text-gray-900">
                    {props.existingTest === undefined ? 'Add a new test' : 'Edit test'}
                  </DialogTitle>
                  <Description className="mb-4 text-center text-sm text-gray-600">
                    {props.existingTest === undefined
                      ? 'Select the test you want to apply to this column.'
                      : 'Edit the test parameters.'}
                  </Description>
                  <div className="w-full">
                    <div className="grid grid-cols-3 gap-4">
                      <dt className="text-sm font-medium leading-6 text-gray-900">Models</dt>
                      <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0 truncate">
                        {[
                          ...new Set(props.selectedModelsAndColumns.map((modelAndColumn) => modelAndColumn.modelName)),
                        ].map((modelName) => (
                          <div key={modelName}>{modelName}</div>
                        ))}
                      </dd>
                    </div>
                    <div className="w-full grid grid-cols-3 gap-4">
                      <dt className="text-sm font-medium leading-6 text-gray-900">Columns</dt>
                      <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
                        {props.selectedModelsAndColumns
                          .map((modelAndColumn) => modelAndColumn.columnName)
                          .map((columnName, index) => (
                            <div key={index}>{columnName}</div>
                          ))}
                      </dd>
                    </div>
                    <div className="w-full mt-2">
                      <DropdownSingleselect
                        label={'Test type'}
                        options={props.selectedModelsAndColumns.length === 1 ? testTypes : ['unique', 'not_null']}
                        selected={selectedTestType ?? ''}
                        setSelected={(value: string) => setSelectedTestType(value as ITestType)}
                        tooltip={
                          selectedTestType !== undefined ? tooltipDescriptions[selectedTestType] : 'Select a test type'
                        }
                      />
                    </div>
                    {selectedTestType === 'accepted_values' && (
                      <div className="mt-2">
                        <label htmlFor="name" className="flex items-center text-sm font-medium leading-6 text-gray-900">
                          <>Accepted values</>
                          <InformationTooltip tooltip="Enter values separated by commas, such as: Active, Inactive, Pending" />
                        </label>
                        <div className="">
                          <div className="flex rounded-md shadow-sm">
                            <input
                              type="text"
                              value={acceptedValues}
                              onChange={(e) => setAcceptedValues(e.target.value)}
                              name="acceptedValues"
                              id="acceptedValues"
                              required
                              className="block rounded-md flex-1 ring-1 ring-inset ring-gray-300 border-0 bg-transparent p-1.5 text-black placeholder:text-gray-400 sm:text-sm disabled:cursor-not-allowed disabled:bg-gray-50 disabled:text-gray-500 disabled:ring-gray-200"
                              placeholder="Comma-separated values"
                            />
                          </div>
                        </div>
                      </div>
                    )}
                    {selectedTestType === 'relationships' && (
                      <>
                        <div className="mt-2">
                          <label
                            htmlFor="name"
                            className="flex items-center text-sm font-medium leading-6 text-gray-900"
                          >
                            <>To</>
                            <InformationTooltip tooltip="Enter the name of the related table or model (e.g., 'orders' or 'sales_data')." />
                          </label>
                          <div className="">
                            <div className="flex rounded-md shadow-sm">
                              <input
                                type="text"
                                value={relationshipToValue}
                                onChange={(e) => setRelationshipToValue(e.target.value)}
                                name="to"
                                id="to"
                                required
                                className="block rounded-md flex-1 ring-1 ring-inset ring-gray-300 border-0 bg-transparent p-1.5 text-black placeholder:text-gray-400 sm:text-sm disabled:cursor-not-allowed disabled:bg-gray-50 disabled:text-gray-500 disabled:ring-gray-200"
                              />
                            </div>
                          </div>
                        </div>
                        <div className="mt-2">
                          <label
                            htmlFor="name"
                            className="flex items-center text-sm font-medium leading-6 text-gray-900"
                          >
                            <>Relation column</>
                            <InformationTooltip tooltip="Enter the column in the related table or model to establish the relationship (e.g., 'order_id')." />
                          </label>
                          <div className="">
                            <div className="flex rounded-md shadow-sm">
                              <input
                                type="text"
                                value={relationField}
                                onChange={(e) => setRelationField(e.target.value)}
                                name="relationColumn"
                                id="relationColumn"
                                required
                                className="block rounded-md flex-1 ring-1 ring-inset ring-gray-300 border-0 bg-transparent p-1.5 text-black placeholder:text-gray-400 sm:text-sm disabled:cursor-not-allowed disabled:bg-gray-50 disabled:text-gray-500 disabled:ring-gray-200"
                              />
                            </div>
                          </div>
                        </div>
                      </>
                    )}
                    {selectedTestType === 'unique_combination_of_columns' && (
                      <div className="mt-2">
                        <label htmlFor="name" className="block text-sm font-medium leading-6 text-gray-900">
                          Columns
                        </label>
                        <div className="">
                          <DropdownMultiselect
                            options={columnsInModel}
                            value={uniqueCombinationOfColumns}
                            onChange={(value) => setUniqueCombinationOfColumns(value)}
                          />
                        </div>
                      </div>
                    )}
                    {showSuccessMessage && successMessage !== '' && (
                      <div className="mt-4 py-1 px-1.5 text-sm w-full rounded bg-green-50 border-2 border-green-500 text-green-600 flex items-center justify-center">
                        {successMessage}
                      </div>
                    )}
                    {showErrorMessage && errorMessage !== '' && (
                      <div className="mt-4 py-1 px-1.5 text-sm w-full rounded bg-red-50 border-2 border-red-500 text-red-600 flex items-center justify-center">
                        {errorMessage}
                      </div>
                    )}
                    {showSummary && summary !== '' && !showErrorMessage && !showSuccessMessage && (
                      <div className="mt-4 py-1 px-1.5 text-sm w-full rounded bg-blue-50 border-2 border-blue-500 text-blue-600 flex items-center justify-center">
                        {summary}
                      </div>
                    )}
                  </div>
                </div>
                <div className="mt-6 flex items-center justify-between gap-2">
                  <button
                    type="button"
                    className="flex-1 mt-3 inline-flex w-full justify-center rounded-md bg-dataops-secondary-blue px-3 py-2 text-sm font-semibold text-gray-50 shadow-sm hover:bg-hover-secondary-blue sm:mt-0 sm:w-auto disabled:bg-gray-400"
                    onClick={() => {
                      if (showSuccessMessage) {
                        setShowSuccessMessage(false);
                        setSelectedTestType(undefined);
                      } else {
                        onAddTest();
                      }
                    }}
                    disabled={getTooltipMessage() !== undefined}
                  >
                    {props.existingTest === undefined
                      ? showSuccessMessage
                        ? 'Add another test'
                        : 'Add test'
                      : 'Update test'}
                    {getTooltipMessage() !== undefined && (
                      <InformationTooltip tooltip={getTooltipMessage() ?? ''} isGray={true} />
                    )}
                  </button>
                  <button
                    type="button"
                    className="flex-1 mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:mt-0 sm:w-auto"
                    ref={cancelButtonRef}
                    onClick={() => onClose()}
                  >
                    Cancel
                  </button>
                </div>
              </DialogPanel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
};

export default AddTestDialog;
