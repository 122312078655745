import React, { useState } from 'react';
import { ChevronRightIcon } from '@heroicons/react/20/solid';
import { Disclosure, DisclosureButton, DisclosurePanel, Transition } from '@headlessui/react';
import { useTooltip, useTooltipInPortal, defaultStyles } from '@visx/tooltip';
import { localPoint } from '@visx/event';
import { useAppSelector } from 'app/hooks';
import { selectSnowflakeAccount } from 'app/snowflakeSlice';
import { useGetSchemasQuery } from 'app/createApi';
import ViewSelectorSchemaItem from './ViewSelectorSchemaItem';
import type { IView } from 'utils/types';
import { classNames } from 'utils/styleUtils';

const tooltipStyles = {
  ...defaultStyles,
  backgroundColor: '#4b5563',
  color: 'white',
  fontSize: 12,
  zIndex: 30,
  maxWidth: 500,
};

interface TooltipData {
  description: string;
}

interface ViewSelectorDatabaseItemProps {
  databaseName: string;
  selectedView: IView | undefined;
  setSelectedView: (view: IView) => void;
}

export default function ViewSelectorDatabaseItem(props: ViewSelectorDatabaseItemProps): JSX.Element {
  const snowflakeAccount = useAppSelector(selectSnowflakeAccount);

  // Only fetch the schemas if the database is expanded
  const [isOpen, setIsOpen] = useState(false);
  const { data: schemas, isLoading } = useGetSchemasQuery(
    {
      account: snowflakeAccount,
      database: props.databaseName,
    },
    { skip: !isOpen },
  );

  const { tooltipOpen, tooltipLeft, tooltipTop, tooltipData, hideTooltip, showTooltip } = useTooltip<TooltipData>();
  const { containerRef, TooltipInPortal } = useTooltipInPortal({
    scroll: true,
  });

  return (
    <>
      <Disclosure>
        {({ open }) => (
          <>
            <div className="w-full relative flex items-center justify-start py-1 px-2 border border-gray-300 bg-gray-50 rounded-md text-gray-700 cursor-default">
              <div className="min-w-[20px] w-5" ref={containerRef}>
                <img className="w-5" src="/static/database-icon.svg" alt="Database" />
              </div>
              <div
                className="text-sm truncate mr-2"
                onMouseLeave={() => {
                  hideTooltip();
                }}
                onMouseMove={(event) => {
                  const eventSvgCoords = localPoint(event);
                  showTooltip({
                    tooltipData: { description: props.databaseName },
                    tooltipTop: eventSvgCoords?.y !== undefined ? eventSvgCoords.y + 10 : undefined,
                    tooltipLeft: eventSvgCoords?.x,
                  });
                }}
              >
                {props.databaseName}
              </div>
              <DisclosureButton className="absolute right-1 w-5 cursor-pointer">
                <ChevronRightIcon
                  onClick={() => setIsOpen(!isOpen)}
                  className={classNames(
                    open ? 'transform rotate-90' : '',
                    'w-5 h-5 ml-auto text-gray-700 transition duration-150 ease-in-out',
                  )}
                />
              </DisclosureButton>
            </div>

            <Transition
              show={open}
              enter="transition duration-100 ease-out"
              enterFrom="transform scale-95 opacity-0"
              enterTo="transform scale-100 opacity-100"
              leave="transition duration-75 ease-out"
              leaveFrom="transform scale-100 opacity-100"
              leaveTo="transform scale-95 opacity-0"
            >
              <DisclosurePanel className="w-full px-4 space-y-1">
                {!isLoading &&
                  schemas?.map((schema) => (
                    <ViewSelectorSchemaItem
                      key={schema}
                      databaseName={props.databaseName}
                      schemaName={schema}
                      selectedView={props.selectedView}
                      setSelectedView={props.setSelectedView}
                    />
                  ))}
                {isLoading && (
                  <div className="w-full h-[30px] border border-gray-300 rounded-md py-1 px-2">
                    <div className="animate-pulse h-2 bg-slate-300 rounded mt-[6px]"></div>
                  </div>
                )}
              </DisclosurePanel>
            </Transition>
          </>
        )}
      </Disclosure>
      {tooltipOpen && tooltipData !== undefined && (
        <TooltipInPortal top={tooltipTop} left={tooltipLeft} style={tooltipStyles}>
          <div className="text-sm">{tooltipData.description}</div>
        </TooltipInPortal>
      )}
    </>
  );
}
