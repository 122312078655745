import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { PURGE } from 'redux-persist';
import type { RootState } from 'app/store';

type ISnowflakeAuthType = 'password' | 'key-pair' | 'persisted' | 'oauth';

export interface ISnowflakeState {
  snowflakeAccountLabel: string;
  snowflakeAccount: string;
  snowflakeUsername: string;
  snowflakeWarehouse: string;
  snowflakeRole: string;
  snowflakeAccountConnected: boolean;
  selectedDatabase: string;
  snowflakeAuthType: ISnowflakeAuthType;
  snowflakePrivateKey: string;
  snowflakePassphrase: string;
}

export const initialState: ISnowflakeState = {
  snowflakeAccountLabel: '',
  snowflakeAccount: '',
  snowflakeUsername: '',
  snowflakeWarehouse: '',
  snowflakeRole: '',
  snowflakeAccountConnected: false,
  selectedDatabase: '',
  snowflakeAuthType: 'password',
  snowflakePrivateKey: '',
  snowflakePassphrase: '',
};

export const snowflakeSlice = createSlice({
  name: 'snowflake',
  initialState,
  reducers: {
    setSnowflakeAccountLabel: (state, action: PayloadAction<string>) => {
      state.snowflakeAccountLabel = action.payload;
    },
    setSnowflakeAccount: (state, action: PayloadAction<string>) => {
      state.snowflakeAccount = action.payload;
    },
    setSnowflakeUsername: (state, action: PayloadAction<string>) => {
      state.snowflakeUsername = action.payload;
    },
    setSnowflakeWarehouse: (state, action: PayloadAction<string>) => {
      state.snowflakeWarehouse = action.payload;
    },
    setSnowflakeRole: (state, action: PayloadAction<string>) => {
      state.snowflakeRole = action.payload;
    },
    setSnowflakeAccountConnected: (state, action: PayloadAction<boolean>) => {
      state.snowflakeAccountConnected = action.payload;
    },
    setSelectedDatabase: (state, action: PayloadAction<string>) => {
      state.selectedDatabase = action.payload;
    },
    setSnowflakeAuthType: (state, action: PayloadAction<ISnowflakeAuthType>) => {
      state.snowflakeAuthType = action.payload;
    },
    setSnowflakePrivateKey: (state, action: PayloadAction<string>) => {
      state.snowflakePrivateKey = action.payload;
    },
    setSnowflakePassphrase: (state, action: PayloadAction<string>) => {
      state.snowflakePassphrase = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(PURGE, () => {
      return initialState;
    });
  },
});

export const {
  setSnowflakeAccountLabel,
  setSnowflakeAccount,
  setSnowflakeUsername,
  setSnowflakeWarehouse,
  setSnowflakeRole,
  setSnowflakeAccountConnected,
  setSelectedDatabase,
  setSnowflakeAuthType,
  setSnowflakePrivateKey,
  setSnowflakePassphrase,
} = snowflakeSlice.actions;

export const selectSnowflakeAccountLabel = (state: RootState): string => state.snowflake.snowflakeAccountLabel;
export const selectSnowflakeAccount = (state: RootState): string => state.snowflake.snowflakeAccount;
export const selectSnowflakeUsername = (state: RootState): string => state.snowflake.snowflakeUsername;
export const selectSnowflakeWarehouse = (state: RootState): string => state.snowflake.snowflakeWarehouse;
export const selectSnowflakeRole = (state: RootState): string => state.snowflake.snowflakeRole;
export const selectSnowflakeAccountConnected = (state: RootState): boolean => state.snowflake.snowflakeAccountConnected;
export const selectSelectedDatabase = (state: RootState): string => state.snowflake.selectedDatabase;
export const selectSnowflakeAuthType = (state: RootState): ISnowflakeAuthType => state.snowflake.snowflakeAuthType;
export const selectSnowflakePrivateKey = (state: RootState): string => state.snowflake.snowflakePrivateKey;
export const selectSnowflakePassphrase = (state: RootState): string => state.snowflake.snowflakePassphrase;

export default snowflakeSlice.reducer;
