import React, { useRef, useState, useEffect } from 'react';
import { useGetDatabasesQuery, useLazyGetViewModelQuery } from 'app/createApi';
import { useAppSelector } from 'app/hooks';
import { selectSnowflakeAccount } from 'app/snowflakeSlice';
import { Dialog, DialogPanel, DialogTitle, Description } from '@headlessui/react';
import ViewSelectorDatabaseItem from './ViewSelectorDatabaseItem';
import LoadingAndErrorSection from 'components/LoadingAndErrorSection';
import type { IView } from 'utils/types';

interface ModelFromViewDialogProps {
  open: boolean;
  setOpen: (open: boolean) => void;
  onCreateNewModel: (modelName?: string, modelSql?: string) => void;
}

const ModelFromViewDialog = (props: ModelFromViewDialogProps): JSX.Element => {
  const cancelButtonRef = useRef(null);
  const snowflakeAccount = useAppSelector(selectSnowflakeAccount);

  const [filteredDatabases, setFilteredDatabases] = useState<string[]>([]);
  const [selectedView, setSelectedView] = useState<IView>();
  const [loading, setLoading] = useState<boolean>(false);
  const [failed, setFailed] = useState<boolean>(false);
  const [searchTerm, setSearchTerm] = useState('');

  const [getViewModel] = useLazyGetViewModelQuery();
  const { data: databases, isLoading: isLoadingDatabases } = useGetDatabasesQuery({
    account: snowflakeAccount,
  });

  useEffect(() => {
    if (databases !== undefined) {
      setFilteredDatabases(databases.filter((database) => database.toLowerCase().includes(searchTerm.toLowerCase())));
    }
  }, [databases, searchTerm]);

  const onSelectionFinalized = (): void => {
    if (selectedView !== undefined) {
      setLoading(true);
      setFailed(false);
      getViewModel({
        account: snowflakeAccount,
        database: selectedView.database,
        schema: selectedView.schema,
        view: selectedView.view,
      })
        .unwrap()
        .then((response) => {
          const processedSql = response.trim();
          props.onCreateNewModel(selectedView.view, processedSql);
          props.setOpen(false);
          setFailed(false);
        })
        .catch((error) => {
          console.error('Failed to create model from view', error);
          setFailed(true);
        })
        .finally(() => {
          setLoading(false);
        });
    }
  };

  const onClose = (): void => {
    props.setOpen(false);
    setLoading(false);
    setFailed(false);
    setSelectedView(undefined);
    setSearchTerm('');
  };

  return (
    <Dialog open={props.open} as="div" className="relative z-10 focus:outline-none" onClose={onClose}>
      <div className="fixed inset-0 z-10 w-screen overflow-y-auto bg-gray-500 bg-opacity-75 transition-opacity">
        <div className="flex min-h-full items-center justify-center p-4">
          <DialogPanel
            transition
            className="w-[22rem] max-w-md rounded-xl bg-white p-4 duration-300 ease-out data-[closed]:transform-[scale(95%)] data-[closed]:opacity-0"
          >
            <DialogTitle as="h3" className="text-xl text-center font-semibold leading-6 text-gray-900">
              Model from view
            </DialogTitle>
            <Description className="mb-4 text-center text-sm text-gray-600">
              Create a new model based on the SQL of an existing view
            </Description>
            <div className="h-[20rem] flex flex-col">
              <div className="w-full grow px-4 space-y-1 overflow-y-scroll">
                {!isLoadingDatabases && (
                  <>
                    <div className="w-full rounded-md shadow-sm ring-1 ring-inset ring-gray-300 mb-4">
                      <input
                        type="text"
                        id="search"
                        name="search"
                        value={searchTerm}
                        onChange={(e) => setSearchTerm(e.target.value)}
                        autoComplete="search"
                        placeholder="Search"
                        className="block w-full flex-1 border-0 bg-transparent p-1 text-black placeholder:text-gray-400 focus:ring-0 sm:text-sm sm:leading-6 outline-none"
                        required
                      />
                    </div>
                    {filteredDatabases.map((database) => (
                      <ViewSelectorDatabaseItem
                        key={database}
                        databaseName={database}
                        selectedView={selectedView}
                        setSelectedView={setSelectedView}
                      />
                    ))}
                    {filteredDatabases.length === 0 && (
                      <div className="text-sm text-center text-gray-600">No databases found</div>
                    )}
                  </>
                )}
                {isLoadingDatabases && (
                  <div className="min-h-8 flex items-center justify-center">
                    <div
                      className="animate-spin inline-block w-8 h-8 border-[2px] border-current border-t-transparent text-cyan-800 rounded-full"
                      role="status"
                      aria-label="loading"
                    >
                      <span className="sr-only">Loading...</span>
                    </div>
                  </div>
                )}
              </div>
              <LoadingAndErrorSection isLoading={loading} isFailed={failed} errorMessage="Failed to create model" />
            </div>
            <div className="mt-6 flex items-center justify-between gap-2">
              <button
                type="button"
                onClick={() => onSelectionFinalized()}
                className="flex-1 mt-3 inline-flex w-full justify-center rounded-md bg-dataops-secondary-blue px-3 py-2 text-sm font-semibold text-gray-50 shadow-sm hover:bg-hover-secondary-blue sm:mt-0 sm:w-auto disabled:bg-gray-400"
                disabled={selectedView === undefined || loading}
              >
                Select
              </button>
              <button
                type="button"
                className="flex-1 mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:mt-0 sm:w-auto"
                ref={cancelButtonRef}
                onClick={() => onClose()}
              >
                Cancel
              </button>
            </div>
          </DialogPanel>
        </div>
      </div>
    </Dialog>
  );
};

export default ModelFromViewDialog;
