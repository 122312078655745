import React from 'react';
import { useTooltip, useTooltipInPortal, defaultStyles } from '@visx/tooltip';
import { localPoint } from '@visx/event';
import type { IView } from 'utils/types';
import { classNames } from 'utils/styleUtils';

const tooltipStyles = {
  ...defaultStyles,
  backgroundColor: '#4b5563',
  color: 'white',
  fontSize: 12,
  zIndex: 30,
  maxWidth: 500,
};

interface TooltipData {
  description: string;
}

interface ViewSelectorViewItemProps {
  databaseName: string;
  schemaName: string;
  viewName: string;
  selectedView: IView | undefined;
  setSelectedView: (view: IView) => void;
}

export default function ViewSelectorViewItem(props: ViewSelectorViewItemProps): JSX.Element {
  const { tooltipOpen, tooltipLeft, tooltipTop, tooltipData, hideTooltip, showTooltip } = useTooltip<TooltipData>();
  const { containerRef, TooltipInPortal } = useTooltipInPortal({
    scroll: true,
  });

  return (
    <>
      <div
        className={classNames(
          props.selectedView?.database === props.databaseName &&
            props.selectedView?.schema === props.schemaName &&
            props.selectedView?.view === props.viewName
            ? 'bg-gray-300 border-gray-500 text-gray-900'
            : 'bg-gray-50 border-gray-300 text-gray-700',
          'w-full relative items-center justify-start flex px-2 py-1 rounded-md border cursor-pointer',
        )}
        onClick={() =>
          props.setSelectedView({ database: props.databaseName, schema: props.schemaName, view: props.viewName })
        }
      >
        <div className="min-w-[20px] w-5 mr-1" ref={containerRef}>
          <img className="w-5" src="/static/table-icon.svg" alt="Table" />
        </div>
        <div
          className="text-sm truncate"
          onMouseLeave={() => {
            hideTooltip();
          }}
          onMouseMove={(event) => {
            const eventSvgCoords = localPoint(event);
            showTooltip({
              tooltipData: { description: props.viewName },
              tooltipTop: eventSvgCoords?.y !== undefined ? eventSvgCoords.y + 10 : undefined,
              tooltipLeft: eventSvgCoords?.x,
            });
          }}
        >
          {props.viewName}
        </div>
      </div>
      {tooltipOpen && tooltipData !== undefined && (
        <TooltipInPortal top={tooltipTop} left={tooltipLeft} style={tooltipStyles}>
          <div className="text-sm">{tooltipData.description}</div>
        </TooltipInPortal>
      )}
    </>
  );
}
