import React from 'react';
import InformationTooltip from 'components/InformationTooltip';
import { Listbox, ListboxButton, ListboxOptions, ListboxOption, Label } from '@headlessui/react';
import { CheckIcon, ChevronUpDownIcon } from '@heroicons/react/20/solid';
import { classNames } from 'utils/styleUtils';

interface DropdownSingleselectProps {
  label: string;
  options: string[];
  selected: string;
  setSelected: (value: string) => void;
  tooltip?: string;
  disabled?: boolean;
}

export default function DropdownSingleselect(props: DropdownSingleselectProps): JSX.Element {
  // useEffect(() => {
  //   const selectedPresent = props.options.some((option) => option === props.selected);
  //   if (props.options.length !== 0 && !selectedPresent) {
  //     props.setSelected('');
  //   }
  // }, [props.options]);

  return (
    <div className="w-full">
      <Listbox disabled={props.disabled === true} value={props.selected} onChange={props.setSelected}>
        <Label className="text-sm font-medium leading-6 text-gray-900 flex items-center">
          <div>{props.label}</div>
          {props.tooltip !== undefined && <InformationTooltip tooltip={props.tooltip} />}
        </Label>
        <ListboxButton
          className={classNames(
            'relative mt-2 w-full cursor-default rounded-md py-1.5 pl-1.5 pr-10 text-left shadow-sm ring-1 ring-inset focus:outline-none focus:ring-2 focus:ring-gray-200 sm:text-sm sm:leading-6',
            props.disabled === true ? 'bg-gray-50 text-gray-500 ring-gray-200' : 'bg-white text-black ring-gray-300',
          )}
        >
          <span className="block truncate">{props.selected}</span>
          <span
            className={classNames(
              'absolute inset-y-0 right-0 flex items-center pr-2',
              props.disabled === true ? '' : 'cursor-pointer',
            )}
          >
            <ChevronUpDownIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
          </span>
          {props.options.length === 0 && (
            <div className="text-gray-400 truncate text-nowrap">{`No ${props.label.toLowerCase()}`}</div>
          )}
          {props.options.length !== 0 && props.selected === '' && (
            <div className="text-gray-400 truncate text-nowrap">{`Select a ${props.label.toLowerCase()}`}</div>
          )}
        </ListboxButton>
        <ListboxOptions
          anchor="bottom"
          transition
          className={classNames(
            'z-50 mt-1 max-h-60 w-[var(--button-width)] [--anchor-gap:var(--spacing-1)] overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm',
            'transition duration-100 ease-in data-[leave]:data-[closed]:opacity-0',
          )}
        >
          {props.options.map((option) => (
            <ListboxOption
              key={option}
              value={option}
              className="group w-full relative cursor-default select-none py-1 pl-3 pr-9 data-[focus]:bg-gray-50"
            >
              <CheckIcon
                className={classNames(props.selected === option ? 'visible' : 'invisible', 'size-4 absolute right-2')}
              />
              <div className={classNames(props.selected === option ? 'text-gray-900' : 'text-gray-600', 'text-sm')}>
                {option}
              </div>
            </ListboxOption>
          ))}
        </ListboxOptions>
      </Listbox>
    </div>
  );
}
