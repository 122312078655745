import React, { useState, useEffect } from 'react';
import { ExclamationTriangleIcon, InformationCircleIcon } from '@heroicons/react/24/outline';
import { useGenerateDbtSourcesModelsMutation, useGetDatabasesQuery } from 'app/createApi';
import { useAppSelector, useAppDispatch } from 'app/hooks';
import { selectProjectPath, selectBranch } from 'app/sharedSlice';
import { selectSnowflakeAccount, selectSnowflakeAccountLabel } from 'app/snowflakeSlice';
import {
  selectSelectedDatabases,
  selectSelectedSchemas,
  selectSelectedTables,
  selectDataProductName,
  selectSchemas,
  selectTables,
  setSoleOutput,
} from '../../data-product-builder/reducers/builderSlice';
import { selectIsPowerUserMode } from '../reducers/astraZenecaSlice';
import StepWrapper from 'components/StepWrapper';
import LoadingAndErrorSection from 'components/LoadingAndErrorSection';
import SourceSelector from '../../data-product-builder/components/SourceSelector';

const azDatabaseList = ['OPS_DP_PUB', 'ENTDATA_PUB', 'OPS_PUB', 'OPS_WRK'];

export interface DatabaseTablesStepProps {
  onBack: () => void;
  onContinue: () => void;
}

export default function DatabaseTablesStep(props: DatabaseTablesStepProps): JSX.Element {
  const dispatch = useAppDispatch();

  const [generateDbtSourcesModels] = useGenerateDbtSourcesModelsMutation();

  const snowflakeAccount = useAppSelector(selectSnowflakeAccount);
  const snowflakeAccountLabel = useAppSelector(selectSnowflakeAccountLabel);
  const selectedDatabases = useAppSelector(selectSelectedDatabases);
  const selectedSchemas = useAppSelector(selectSelectedSchemas);
  const selectedTables = useAppSelector(selectSelectedTables);
  const schemas = useAppSelector(selectSchemas);
  const tables = useAppSelector(selectTables);
  const dataProductName = useAppSelector(selectDataProductName);
  const projectPath = useAppSelector(selectProjectPath);
  const branch = useAppSelector(selectBranch);

  const isPowerUserMode = useAppSelector(selectIsPowerUserMode);

  const [loading, setLoading] = useState<boolean>(false);
  const [failed, setFailed] = useState<boolean>(false);
  const [errorMessage] = useState<string>(
    'Oops! The source selection failed. Please try again or contact support@dataops.live if the issue persists.',
  );

  const [existingAzDatabases, setExistingAzDatabases] = useState<string[]>([]);

  const { data: databases, isLoading } = useGetDatabasesQuery({ account: snowflakeAccount });

  useEffect(() => {
    if (databases === undefined) {
      setExistingAzDatabases([]);
    } else {
      setExistingAzDatabases(azDatabaseList.filter((db) => databases.includes(db)));
    }
  }, [databases]);

  const onContinue = (): void => {
    if (isPowerUserMode) {
      props.onContinue();
    }

    setLoading(true);
    setFailed(false);

    const databaseToCloneSchemas = 'OPS_DP_PUB';

    let cloneSchemas: string[] = [];
    if (selectedDatabases.some((obj) => obj.database === databaseToCloneSchemas)) {
      cloneSchemas = schemas[databaseToCloneSchemas].map((obj) => obj.schema);
    } else {
      cloneSchemas = selectedSchemas
        .filter((obj) => obj.database === databaseToCloneSchemas)
        .map((obj) => `${databaseToCloneSchemas}.${obj.schema}`);
      cloneSchemas = cloneSchemas.concat(
        selectedTables
          .filter((obj) => obj.database === databaseToCloneSchemas)
          .map((obj) => `${databaseToCloneSchemas}.${obj.schema}`),
      );
    }

    let tablesToSend: any[] = [];

    selectedDatabases.forEach((database) => {
      const databaseId = database.database;
      if (schemas[databaseId] !== undefined) {
        const schemasInDatabase = schemas[databaseId].map((obj) => obj.schema);
        schemasInDatabase.forEach((schema) => {
          const schemaId = `${databaseId}.${schema}`;
          const tablesInSchema = tables[schemaId].map((obj) => obj.table);
          tablesToSend = tablesToSend.concat(
            tablesInSchema.map((table: any) => ({
              database: databaseId,
              schema,
              object_id: table,
            })),
          );
        });
      }
    });

    selectedSchemas.forEach((schema) => {
      const schemaId = `${schema.database}.${schema.schema}`;
      const tablesInSchema = tables[schemaId].map((obj) => obj.table);
      tablesToSend = tablesToSend.concat(
        tablesInSchema.map((table: any) => ({
          database: schema.database,
          schema: schema.schema,
          object_id: table,
        })),
      );
    });

    selectedTables.forEach((table) => {
      tablesToSend.push({ database: table.database, schema: table.schema, object_id: table.table });
    });

    generateDbtSourcesModels({
      projectName: projectPath,
      dataProductName,
      account: snowflakeAccount,
      branch,
      cloneSchemas,
      tables: tablesToSend,
    })
      .unwrap()
      .then((res: any) => {
        dispatch(setSoleOutput(res));
        props.onContinue();
      })
      .catch((err) => {
        console.log(err);
        setFailed(true);
        setLoading(false);
        // Currently we use a generic error message and not the one returned by the backend
        // setErrorMessage(err.data);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <StepWrapper
      title="Which data sources would you like to use in your Data Product?"
      subtitle="Select the tables to include in your Data Product"
      onBack={() => props.onBack()}
      onContinue={() => onContinue()}
      isLoading={loading}
      continueDisabled={
        (selectedDatabases.length === 0 && selectedSchemas.length === 0 && selectedTables.length === 0) || loading
      }
    >
      <div className="w-full h-full flex flex-col items-center">
        {(existingAzDatabases.length !== 0 || isLoading) && (
          <div className="flex w-full">
            <div className="w-1/2 h-full flex flex-col items-center">
              <div className="relative w-3/4 mb-3">
                <div className="absolute inset-0 flex items-center" aria-hidden="true">
                  <div className="w-full border-t border-gray-400" />
                </div>
                <div className="relative flex justify-center">
                  <span className="bg-white px-2 text-sm text-gray-600">{snowflakeAccountLabel}</span>
                </div>
              </div>
              {!isLoading && (
                <SourceSelector
                  mode="selection"
                  objectType="table"
                  highlightedDatabase={'OPS_DP_PUB'}
                  databases={existingAzDatabases}
                />
              )}
              {isLoading && (
                <div className="w-3/4 space-y-1">
                  <div className="w-full max-w-[25rem] h-[30px] border border-gray-300 rounded-md py-1 px-2">
                    <div className="animate-pulse h-2 bg-slate-300 rounded mt-[6px]"></div>
                  </div>
                  <div className="w-full max-w-[25rem] h-[30px] border border-gray-300 rounded-md py-1 px-2">
                    <div className="animate-pulse h-2 bg-slate-300 rounded mt-[6px]"></div>
                  </div>
                  <div className="w-full max-w-[25rem] h-[30px] border border-gray-300 rounded-md py-1 px-2">
                    <div className="animate-pulse h-2 bg-slate-300 rounded mt-[6px]"></div>
                  </div>
                  <div className="w-full max-w-[25rem] h-[30px] border border-gray-300 rounded-md py-1 px-2">
                    <div className="animate-pulse h-2 bg-slate-300 rounded mt-[6px]"></div>
                  </div>
                </div>
              )}
            </div>
            <div className="w-1/2 h-full flex flex-col items-center">
              <div className="relative w-3/4 mb-3">
                <div className="absolute inset-0 flex items-center" aria-hidden="true">
                  <div className="w-full border-t border-gray-400" />
                </div>
                <div className="relative flex justify-center">
                  <span className="bg-white px-2 text-sm text-gray-600">{dataProductName}</span>
                </div>
              </div>
              <SourceSelector
                mode="preview"
                objectType="table"
                highlightedDatabase={'OPS_DP_PUB'}
                databases={existingAzDatabases}
              />
            </div>
          </div>
        )}
        {existingAzDatabases.length === 0 && !isLoading && (
          <div className="mt-8 w-3/4 max-w-[50rem] rounded-md border border-yellow-700 bg-yellow-50 p-4 flex">
            <div className="flex-shrink-0">
              <ExclamationTriangleIcon className="h-5 w-5 text-yellow-700" aria-hidden="true" />
            </div>
            <div className="ml-3 flex-1 md:flex md:justify-between">
              <p className="yellow-sm text-yellow-700">{`None of the following databases are present in the Snowflake account or the provided user and role doesn't have access to them : ${azDatabaseList.join(
                ', ',
              )}`}</p>
            </div>
          </div>
        )}
        <LoadingAndErrorSection isLoading={loading} isFailed={failed} errorMessage={errorMessage} hideLoading={true} />
        <div className="mt-[auto]">
          <div className="max-w-[50rem] mt-6 mx-[auto] rounded-md bg-blue-50 p-4 flex">
            <div className="flex-shrink-0">
              <InformationCircleIcon className="h-5 w-5 text-blue-400" aria-hidden="true" />
            </div>
            <div>
              <div className="ml-3 flex-1 md:flex md:justify-between">
                <p className="text-sm text-blue-700 font-semibold">Get Ready!</p>
              </div>
              <div className="ml-3 flex-1 md:flex md:justify-between">
                <p className="text-sm text-blue-700">
                  Soon, <span className="font-semibold">DataOps.live Assist</span> will intelligently recommend the
                  perfect tables for your data product, streamlining your workflow and boosting your success like never
                  before!
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </StepWrapper>
  );
}
