import { createApi, retry } from '@reduxjs/toolkit/query/react';
import { axiosBaseQuery } from 'app/baseQuery';
import { getSnakeCase } from 'utils/formatUtils';
import type { ISchemaLevelObject } from 'utils/types';
import type { IModelsFileContent } from 'features/astra-zeneca-flow/types/testEditorTypes';

export const builderApi = createApi({
  reducerPath: 'builderApi',
  baseQuery: retry(
    axiosBaseQuery({
      baseUrl: '/api/v1',
    }),
    {
      retryCondition: (e: any, __, { attempt }) => {
        if (e.originalStatus !== undefined && e.originalStatus >= 500 && e.originalStatus < 600 && attempt <= 3) {
          return true;
        }
        return false;
      },
    },
  ),
  keepUnusedDataFor: 600,
  tagTypes: ['Builder', 'SnowflakeAccount', 'MetadataFileContent', 'Models', 'DefaultDatabase', 'OAuthIntegrations'],
  endpoints: (builder) => ({
    generateDataProductSpecification: builder.mutation({
      query: ({
        projectName,
        isNewProject,
        name,
        description,
        dataQualityTests,
        version,
        owner,
        group,
        projectType,
        governanceData,
      }) => ({
        url: '/builder/generate_data_product_specification',
        method: 'POST',
        params: {
          project_name: projectName,
          is_new_project: isNewProject,
          project_type: projectType ?? 'default',
          governance_data: governanceData ?? false,
        },
        data: { name, description, data_quality_tests: dataQualityTests, version, owner, group },
      }),
      invalidatesTags: ['DefaultDatabase'],
    }),
    generateSoleFromObjects: builder.mutation({
      query: ({ account, databases, schemas, tables }) => ({
        url: '/builder/generate_sole_from_objects',
        method: 'POST',
        params: { account },
        data: { databases, schemas, tables },
      }),
      invalidatesTags: [],
    }),
    generateSoleFromDDL: builder.mutation({
      query: ({ ddl }) => ({
        url: '/builder/generate_sole_from_ddl',
        method: 'POST',
        data: { ddl },
      }),
      invalidatesTags: [],
    }),
    sole: builder.mutation({
      query: ({
        dbtSole,
        manageSole,
        toIncludeModels,
        toWriteConfigs,
        toWriteJobs,
        dataProductName,
        projectName,
        account,
        branch,
        augment,
      }) => ({
        url: '/builder/sole',
        method: 'POST',
        params: {
          to_include_models: toIncludeModels,
          to_write_configs: toWriteConfigs,
          to_write_jobs: toWriteJobs,
          data_product_name: dataProductName,
          project_name: projectName,
          account,
          branch,
          augment,
        },
        data: { manage_sole: manageSole, dbt_sole: dbtSole },
      }),
      invalidatesTags: [],
    }),
    generateDbtSourcesModels: builder.mutation({
      query: ({ projectName, dataProductName, account, branch, cloneSchemas, tables }) => ({
        url: '/builder/generate_dbt_sources_models',
        method: 'POST',
        params: {
          project_name: projectName,
          data_product_name: dataProductName,
          account,
          branch,
          to_include_models: false,
          to_write_configs: true,
          to_write_jobs: false,
        },
        data: { clone_schemas: cloneSchemas, tables },
      }),
      invalidatesTags: [],
    }),
    pipeline: builder.mutation({
      query: ({ dataProductName, projectName, branch, mergeMain, camelCase }) => ({
        url: '/builder/pipeline',
        method: 'POST',
        params: {
          data_product_name: dataProductName,
          project_name: projectName,
          branch,
          merge_main: mergeMain,
          camel_case: camelCase ?? true,
        },
      }),
      invalidatesTags: [],
    }),
    addSnowflakeAccount: builder.mutation({
      query: ({ account, username, password, warehouse, role }) => ({
        url: '/snowflake_accounts/add_snowflake_account',
        method: 'POST',
        data: {
          account,
          username,
          password,
          warehouse,
          role,
          is_mfa: true,
          persist: true,
        },
      }),
      invalidatesTags: ['SnowflakeAccount'],
    }),
    addSnowflakeAccountPKey: builder.mutation({
      query: ({ account, username, warehouse, role, privateKey, privateKeyPwd }) => ({
        url: '/snowflake_accounts/add_snowflake_account_pkey',
        method: 'POST',
        data: {
          account,
          username,
          warehouse,
          role,
          private_key: privateKey,
          persist: true,
          ...(privateKeyPwd !== '' ? { private_key_pwd: privateKeyPwd } : {}),
        },
      }),
      invalidatesTags: ['SnowflakeAccount'],
    }),
    getSnowflakeAccounts: builder.query<any, {}>({
      query: () => ({
        url: '/snowflake_accounts/snowflake_accounts',
      }),
      providesTags: ['SnowflakeAccount'],
    }),
    deleteSnowflakeAccount: builder.mutation({
      query: ({ account }) => ({
        url: '/snowflake_accounts/snowflake_accounts',
        method: 'DELETE',
        params: { account },
      }),
      invalidatesTags: ['SnowflakeAccount'],
    }),
    getDatabases: builder.query<string[], { account: string }>({
      query: (params) => ({
        url: '/builder/databases',
        params: { ...params },
      }),
      providesTags: ['SnowflakeAccount'],
    }),
    getSchemas: builder.query<string[], { account: string; database: string }>({
      query: (params) => ({
        url: '/builder/schemas',
        params: { ...params },
      }),
      providesTags: ['SnowflakeAccount'],
    }),
    getSchemaLevelObjects: builder.query<
      string[],
      { account: string; database: string; schema: string; object_type: ISchemaLevelObject }
    >({
      query: (params) => ({
        url: '/builder/schema_level_objects',
        params: { ...params, unsupported: true },
      }),
      providesTags: ['SnowflakeAccount'],
    }),
    createDbtMigrationProject: builder.mutation({
      query: ({ projectName, isNewProject }) => ({
        url: '/builder/create_dbt_migration_project',
        method: 'POST',
        params: {
          project_name: projectName,
          is_new_project: isNewProject,
        },
      }),
      invalidatesTags: [],
    }),
    generateDbtJobs: builder.mutation({
      query: ({
        projectName,
        masterDatabase,
        account,
        dbtVersion,
        branch,
        jobList,
        seedSelector,
        sourceSelector,
        modelSelector,
      }) => ({
        url: '/builder/generate_dbt_jobs',
        method: 'POST',
        params: {
          project_name: projectName,
          master_database: masterDatabase,
          account,
          dbt_version: dbtVersion,
          branch,
        },
        data: {
          jobs_list: jobList,
          seed_selector: seedSelector,
          source_selector: sourceSelector,
          models_selector: modelSelector,
        },
      }),
      invalidatesTags: [],
    }),
    nativeAppCreateProject: builder.mutation({
      query: ({ projectName }) => ({
        url: '/native_app/create_project',
        method: 'POST',
        params: {
          project_name: projectName,
          is_new_project: true,
        },
      }),
      invalidatesTags: [],
    }),
    nativeAppCreateModules: builder.mutation({
      query: ({
        projectName,
        account,
        modules,
        secondaryAccounts,
        nativeAppName,
        packageName,
        packageRole,
        packageWarehouse,
        applicationName,
        applicationRole,
        applicationWarehouse,
        deploymentType,
        streamlitSchema,
        spcsComputePoolName,
        spcsRepositoryName,
        spcsRepositorySchema,
      }) => ({
        url: '/native_app/create_modules',
        method: 'POST',
        params: {
          project_name: projectName,
          account,
          is_new_project: true,
          compute_pool: spcsComputePoolName,
          snowflake_schema: streamlitSchema,
          spcs_repo_name: spcsRepositoryName,
          spcs_repo_schema: spcsRepositorySchema,
        },
        data: {
          modules: {
            modules: [
              ...modules,
              {
                module_type: 'native-app',
                name: nativeAppName,
                description: '',
                deployment_type: deploymentType,
                query_warehouse: packageWarehouse,
                package_role: packageRole,
                package_name: packageName,
                package_warehouse: packageWarehouse,
                application_role: applicationRole,
                application_name: applicationName,
                application_warehouse: applicationWarehouse,
              },
            ],
          },
          secondary_accounts: secondaryAccounts,
        },
      }),
      invalidatesTags: [],
    }),
    dataAppCreateModules: builder.mutation({
      query: ({ projectName, account, modules, secondaryAccounts }) => ({
        url: '/native_app/create_modules',
        method: 'POST',
        params: {
          project_name: projectName,
          account,
          is_new_project: true,
        },
        data: {
          modules: {
            modules,
          },
          secondary_accounts: secondaryAccounts,
        },
      }),
      invalidatesTags: [],
    }),
    astraZenecaMetadata: builder.mutation({
      query: ({
        projectName,
        branch,
        name,
        description,
        jiraTicketNo,
        owner,
        dataProductManager,
        version,
        versionDescription,
        schemaDescription,
        dataProductCategory,
        dataProductType,
        containsPatientData,
        containsPersonalData,
        isGxpValidated,
        isSoxControlled,
        purpose,
        specification1,
        sourceSystem,
        l1BusinessCapability,
        format,
        dataOffice,
        application,
        processingFrequency,
        gradCode,
        lineOfBusinessOwningDP,
        businessCapability,
        fairProfileName,
        meetsUseCase,
        url,
        businessTerm,
        businessTermDefinition,
      }) => ({
        url: '/builder/generate_json_metadata',
        method: 'POST',
        params: {
          project_name: projectName,
          branch,
        },
        data: {
          name,
          description,
          jira_ticket_no: jiraTicketNo,
          owner,
          data_product_manager: dataProductManager,
          version,
          version_description: versionDescription,
          schema_description: schemaDescription,
          data_product_category: dataProductCategory,
          data_product_type: dataProductType,
          contains_patient_data: containsPatientData,
          contains_personal_data: containsPersonalData,
          is_gxp_validated: isGxpValidated,
          is_sox_controlled: isSoxControlled,
          purpose,
          specification_1: specification1,
          source_system: sourceSystem,
          l1_business_capability: l1BusinessCapability,
          format,
          data_office: dataOffice,
          application,
          processing_frequency: processingFrequency,
          grad_code: gradCode,
          line_of_business_owning_dp: lineOfBusinessOwningDP,
          business_capability: businessCapability,
          fair_profile_name: fairProfileName,
          meets_use_case: meetsUseCase,
          url,
          business_term: businessTerm,
          business_term_definition: businessTermDefinition,
        },
      }),
      invalidatesTags: ['MetadataFileContent'],
    }),
    getMetadataFileContent: builder.query<string[], { projectPath: string; branch: string }>({
      query: (params) => ({
        url: '/builder/metadata_file_content',
        params: { project_path: params.projectPath, branch: params.branch },
      }),
      providesTags: ['MetadataFileContent'],
    }),
    getModelsFileContent: builder.query<
      IModelsFileContent,
      { projectPath: string; branch: string; dataProductName: string; materialization: string }
    >({
      query: (params) => ({
        url: '/builder/models_file_content',
        params: {
          project_path: params.projectPath,
          branch: params.branch,
          data_product_name: params.dataProductName,
          models_file_path: `dataops/modelling/models/${params.materialization}/${getSnakeCase(
            params.dataProductName,
          )}/_${params.materialization.charAt(0)}_${getSnakeCase(params.dataProductName)}_schema.yml`,
        },
      }),
      providesTags: ['Models'],
    }),
    setModelsFileContent: builder.mutation({
      query: ({ projectPath, branch, dataProductName, modelsFileContent, materialization }) => ({
        url: '/builder/models_file_content',
        method: 'POST',
        params: {
          project_path: projectPath,
          branch,
          data_product_name: dataProductName,
          models_file_path: `dataops/modelling/models/${materialization as string}/${getSnakeCase(dataProductName)}/_${
            materialization.charAt(0) as string
          }_${getSnakeCase(dataProductName)}_schema.yml`,
        },
        data: {
          version: modelsFileContent.version,
          models: modelsFileContent.models,
        },
      }),
      invalidatesTags: ['Models'],
    }),
    getSqlPaths: builder.query<string[], { projectPath: string; branch: string }>({
      query: (params) => ({
        url: '/builder/sql_paths',
        params: { project_path: params.projectPath, branch: params.branch, dbt_path: 'dataops/modelling' },
      }),
      providesTags: ['Models'],
    }),
    getModel: builder.query<string, { projectPath: string; branch: string; dbtPath: string }>({
      query: (params) => ({
        url: '/builder/model',
        params: {
          project_path: params.projectPath,
          branch: params.branch,
          dbt_path: params.dbtPath,
        },
      }),
      providesTags: ['Models'],
    }),
    setModel: builder.mutation({
      query: ({ projectPath, branch, modelPath, materialization, dataProductName, sql, defaultDatabase }) => ({
        url: '/builder/model',
        method: 'POST',
        params: {
          project_path: projectPath,
          branch,
          model_path: modelPath,
          data_product_name: dataProductName,
          models_sink: `dataops/modelling/models/${materialization as string}/${getSnakeCase(dataProductName)}/_${
            materialization.charAt(0) as string
          }_${getSnakeCase(dataProductName)}_schema.yml`,
          default_database: defaultDatabase,
        },
        data: { model: sql },
      }),
      invalidatesTags: ['Models'],
    }),
    deleteModel: builder.mutation({
      query: ({ projectPath, branch, dbtPath, materialization, dataProductName }) => ({
        url: '/builder/model',
        method: 'DELETE',
        params: {
          project_path: projectPath,
          branch,
          dbt_path: dbtPath,
          models_sink: `dataops/modelling/models/${materialization as string}/${getSnakeCase(dataProductName)}/_${
            materialization.charAt(0) as string
          }_${getSnakeCase(dataProductName)}_schema.yml`,
        },
      }),
      invalidatesTags: ['Models'],
    }),
    generateDbtSources: builder.mutation({
      query: ({ projectPath, branch, dataProductName, account, database }) => ({
        url: '/builder/generate_dbt_sources',
        method: 'POST',
        params: {
          project_path: projectPath,
          branch,
          dbt_path: 'dataops/modelling',
          data_product_name: dataProductName,
          account,
          database,
          to_include_models: false,
          to_write_configs: true,
          to_write_jobs: false,
        },
      }),
      invalidatesTags: ['Models', 'DefaultDatabase'],
    }),
    validateModel: builder.mutation({
      query: ({ account, database, sql }) => ({
        url: '/builder/validate_model',
        method: 'POST',
        params: {
          account,
          database,
        },
        data: { model: sql },
      }),
      invalidatesTags: [],
    }),
    getViewModel: builder.query<string, { account: string; database: string; schema: string; view: string }>({
      query: (params) => ({
        url: '/builder/view_model',
        params: { ...params },
      }),
      providesTags: ['Models'],
    }),
    getDefaultDatabase: builder.query<string, { projectPath: string; branch: string }>({
      query: (params) => ({
        url: '/builder/default_database',
        params: {
          project_path: params.projectPath,
          branch: params.branch,
        },
      }),
      providesTags: ['DefaultDatabase'],
    }),
    getOauthIntegrations: builder.query<any, {}>({
      query: () => ({
        url: '/snowflake_accounts/integrations',
      }),
      providesTags: ['OAuthIntegrations'],
    }),
    formatSql: builder.mutation({
      query: ({ sql }) => ({
        url: '/builder/format_sql',
        method: 'POST',
        data: {
          sql,
          to_augment: false,
          fluff_project: '15777',
          fluff_path: 'dataops/profiles/.sqlfluff',
          fluff_branch: 'main',
        },
      }),
      invalidatesTags: ['Models'],
    }),
  }),
});

export const {
  useGenerateDataProductSpecificationMutation,
  useGenerateSoleFromObjectsMutation,
  useGenerateSoleFromDDLMutation,
  useSoleMutation,
  useGenerateDbtSourcesModelsMutation,
  usePipelineMutation,
  useAddSnowflakeAccountMutation,
  useAddSnowflakeAccountPKeyMutation,
  useGetSnowflakeAccountsQuery,
  useDeleteSnowflakeAccountMutation,
  useGetDatabasesQuery,
  useGetSchemasQuery,
  useLazyGetSchemasQuery,
  useGetSchemaLevelObjectsQuery,
  useLazyGetSchemaLevelObjectsQuery,
  useCreateDbtMigrationProjectMutation,
  useGenerateDbtJobsMutation,
  useNativeAppCreateProjectMutation,
  useNativeAppCreateModulesMutation,
  useDataAppCreateModulesMutation,
  useAstraZenecaMetadataMutation,
  useLazyGetMetadataFileContentQuery,
  useGetModelsFileContentQuery,
  useSetModelsFileContentMutation,
  useGetSqlPathsQuery,
  useLazyGetModelQuery,
  useSetModelMutation,
  useDeleteModelMutation,
  useGenerateDbtSourcesMutation,
  useValidateModelMutation,
  useLazyGetViewModelQuery,
  useGetDefaultDatabaseQuery,
  useGetOauthIntegrationsQuery,
  useFormatSqlMutation,
} = builderApi;
