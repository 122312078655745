import React, { useState, useEffect, useRef } from 'react';
import { Switch, Field, Label } from '@headlessui/react';
import {
  useGenerateDataProductSpecificationMutation,
  useLazyGetMetadataFileContentQuery,
  useLazyGetSchemasQuery,
  useLazyGetSchemaLevelObjectsQuery,
  useGetSnowflakeAccountsQuery,
} from 'app/createApi';
import { useAppSelector, useAppDispatch } from 'app/hooks';
import {
  selectCreateNewProject,
  selectSelectedGroup,
  selectSelectedProject,
  selectProjectsCreatedInCurrentSession,
  selectNewProjectName,
  setNewProjectName,
  setProjectPath,
  setProjectsCreatedInCurrentSession,
  setBranch,
} from 'app/sharedSlice';
import {
  selectSnowflakeAccount,
  setSnowflakeAccount,
  setSnowflakeAccountConnected,
  setSnowflakeAccountLabel,
} from 'app/snowflakeSlice';
import {
  setSkipSnowflakeConnectionStep,
  selectName,
  setName,
  selectDescription,
  setDescription,
  selectVersion,
  setVersion,
  selectOwner,
  setOwner,
  selectJiraTicketNo,
  setJiraTicketNo,
  selectDataProductManager,
  setDataProductManager,
  selectVersionDescription,
  setVersionDescription,
  selectSchemaDescription,
  setSchemaDescription,
  selectDataProductCategory,
  setDataProductCategory,
  selectDataProductType,
  setDataProductType,
  selectContainsPatientData,
  setContainsPatientData,
  selectContainsPersonalData,
  setContainsPersonalData,
  selectIsGxpValidated,
  setIsGxpValidated,
  selectIsSoxControlled,
  setIsSoxControlled,
  selectPurpose,
  setPurpose,
  selectSpecification1,
  setSpecification1,
  selectSourceSystem,
  setSourceSystem,
  setL1BusinessCapability,
  selectL1BusinessCapability,
  selectL1BusinessCapabilityMainCategory,
  setL1BusinessCapabilityMainCategory,
  selectL1BusinessCapabilitySubCategory,
  setL1BusinessCapabilitySubCategory,
  setFormat,
  selectFormat,
  setDataOffice,
  selectDataOffice,
  setApplication,
  selectApplication,
  setProcessingFrequency,
  selectProcessingFrequency,
  setGradCode,
  selectGradCode,
  setLineOfBusinessOwningDP,
  selectLineOfBusinessOwningDP,
  setBusinessCapability,
  selectBusinessCapability,
  setFairProfileName,
  selectFairProfileName,
  setMeetsUseCase,
  selectMeetsUseCase,
  setUrl,
  selectUrl,
  setBusinessTerm,
  selectBusinessTerm,
  setBusinessTermDefinition,
  selectBusinessTermDefinition,
  selectIsPowerUserMode,
  setIsPowerUserMode,
  setReviewedByInformationArchitect,
  setModels,
} from '../reducers/astraZenecaSlice';
import {
  setDataProductName,
  selectTable,
  selectSchemas,
  selectTables,
  addSchemas,
  addTables,
  clearSelectedObjects,
} from 'features/data-product-builder/reducers/builderSlice';
import StepWrapper from 'components/StepWrapper';
import GroupAndProjectSelector, { sanitizeProjectName } from 'components/GroupAndProjectSelector';
import LoadingAndErrorSection from 'components/LoadingAndErrorSection';
import DividerWithText from 'components/DividerWithText';
import DropdownSingleselect from 'components/DropdownSingleselect';
import InformationTooltip from 'components/InformationTooltip';
import { classNames } from 'utils/styleUtils';
import { getCamelCase, isProjectNameValid } from 'utils/formatUtils';
import { ITable } from 'utils/types';

export interface DataProductDefinitionStepProps {
  onBack: () => void;
  onContinue: () => void;
}

const defaultErrorMessage =
  'Oops! Failed to create the project. Please try again or contact support@dataops.live if the issue persists.';

export default function DataProductDefinitionStep(props: DataProductDefinitionStepProps): JSX.Element {
  const dispatch = useAppDispatch();
  const createNewProject = useAppSelector(selectCreateNewProject);
  const selectedGroup = useAppSelector(selectSelectedGroup);
  const selectedProject = useAppSelector(selectSelectedProject);
  const projectsCreatedInCurrentSession = useAppSelector(selectProjectsCreatedInCurrentSession);
  const newProjectName = useAppSelector(selectNewProjectName);
  const snowflakeAccount = useAppSelector(selectSnowflakeAccount);

  const name = useAppSelector(selectName);
  const description = useAppSelector(selectDescription);
  const version = useAppSelector(selectVersion);
  const owner = useAppSelector(selectOwner);
  const jiraTicket = useAppSelector(selectJiraTicketNo);
  const dataProductManager = useAppSelector(selectDataProductManager);
  const versionDescription = useAppSelector(selectVersionDescription);
  const schemaDescription = useAppSelector(selectSchemaDescription);
  const dataProductCategory = useAppSelector(selectDataProductCategory);
  const dataProductType = useAppSelector(selectDataProductType);
  const containsPatientData = useAppSelector(selectContainsPatientData);
  const containsPersonalData = useAppSelector(selectContainsPersonalData);
  const isGxpValidated = useAppSelector(selectIsGxpValidated);
  const isSoxControlled = useAppSelector(selectIsSoxControlled);
  const purpose = useAppSelector(selectPurpose);
  const specification = useAppSelector(selectSpecification1);
  const sourceSystem = useAppSelector(selectSourceSystem);

  const l1BusinessCapability = useAppSelector(selectL1BusinessCapability);
  const l1BusinessCapabilityMainCategory = useAppSelector(selectL1BusinessCapabilityMainCategory);
  const l1BusinessCapabilitySubCategory = useAppSelector(selectL1BusinessCapabilitySubCategory);
  const format = useAppSelector(selectFormat);
  const dataOffice = useAppSelector(selectDataOffice);
  const application = useAppSelector(selectApplication);
  const processingFrequency = useAppSelector(selectProcessingFrequency);
  const gradCode = useAppSelector(selectGradCode);
  const lineOfBusinessOwningDP = useAppSelector(selectLineOfBusinessOwningDP);
  const businessCapability = useAppSelector(selectBusinessCapability);
  const fairProfileName = useAppSelector(selectFairProfileName);
  const meetsUseCase = useAppSelector(selectMeetsUseCase);
  const url = useAppSelector(selectUrl);
  const businessTerm = useAppSelector(selectBusinessTerm);
  const businessTermDefinition = useAppSelector(selectBusinessTermDefinition);

  const isPowerUserMode = useAppSelector(selectIsPowerUserMode);

  const schemas = useAppSelector(selectSchemas);
  const tables = useAppSelector(selectTables);

  const [subCategoryOptions, setSubCategoryOptions] = useState<string[]>([]);

  const [loading, setLoading] = useState<boolean>(false);
  const [success, setSuccess] = useState<boolean>(false);
  const [failed, setFailed] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string>(defaultErrorMessage);

  const [metadataLoading, setMetadataLoading] = useState<boolean>(false);

  const [generateDataProductSpecification] = useGenerateDataProductSpecificationMutation();
  const [getMetadataFileContent] = useLazyGetMetadataFileContentQuery();
  const [getSchemas] = useLazyGetSchemasQuery();
  const [getSchemaLevelObjects] = useLazyGetSchemaLevelObjectsQuery();

  const { data: snowflakeAccounts } = useGetSnowflakeAccountsQuery({});

  const formRef = useRef<HTMLFormElement>(null);

  useEffect(() => {
    if (
      snowflakeAccounts !== undefined &&
      Object.keys(snowflakeAccounts).length > 0 &&
      snowflakeAccount in snowflakeAccounts
    ) {
      dispatch(setSnowflakeAccountLabel(snowflakeAccounts[snowflakeAccount]?.account));
    }
  }, [snowflakeAccounts, snowflakeAccount]);

  useEffect(() => {
    let tmpSubCategoryOptions: string[] = [];
    switch (l1BusinessCapabilityMainCategory) {
      case 'Make & Supply Products':
        tmpSubCategoryOptions = [
          'Achieve Regulatory Approval',
          'Manage Distribution Logistics & Distribution Warehousing',
          'Manage Supply Chain product lifecycle',
          'Manage product supply chain visibility & integrity',
          'Manufacture & Pack product',
          'Operations Acquire, Construct, and Manage Assets',
          'Manage Quality Control',
          'Procure Direct materials and services',
          'Deliver',
          'Plan Supply Chain',
          'Manage Supply Chain',
          'Contract Manufacture Product',
          'Manage Quality Assurance',
          'Source',
        ];
        break;
      case 'Discover & Develop Drug':
        tmpSubCategoryOptions = [
          'Develop BioPharmaceutical Product',
          'Discover Small Molecule Candidate Drug',
          'Manage R&D',
          'Discover Biologics Candidate Drug',
          'Laboratory Operations',
          'Manage Regulatory Approvals and Compliance',
          'Develop Pharmaceutical Product',
          'Perform Translational Sciences',
          'Target Identification and Validation',
          'Evaluate Real World Evidence',
          'Assure R&D Quality',
          'Manufacturing Support',
          'Perform Clinical Development',
          'Ensure Patient Safety',
        ];
        break;
      case 'Manage & Enable AZ':
        tmpSubCategoryOptions = [
          'Manage Environmental Health & Safety',
          'Manage Information Technology',
          'Manage Data as an Enterprise Asset',
          'Manage External & Internal Relationships',
          'Manage Legal Issues',
          'Manage Enterprise Risk & Compliance',
          'Develop and Run Business Shared Services',
          'Conduct Business Development',
          'Manage Financial Resources',
          'Manage & Develop Human Capital',
          'Acquire, Construct, and Manage Assets',
          'Develop & Manage Healthcare Services',
        ];
        break;
      case 'Market & Sell':
        tmpSubCategoryOptions = [
          'Engage Healthcare Professionals',
          'Engage with Patients',
          'Deliver',
          'Conduct Medical Affairs',
          'Enabling HCP Engagement',
          'Market Products',
          'Gain Market Access',
          'Manage Commercial Strategy',
        ];
        break;
    }

    setSubCategoryOptions(tmpSubCategoryOptions);

    if (!tmpSubCategoryOptions.includes(l1BusinessCapabilitySubCategory)) {
      dispatch(setL1BusinessCapabilitySubCategory(''));
    }
  }, [l1BusinessCapabilityMainCategory]);

  useEffect(() => {
    dispatch(setL1BusinessCapability(`${l1BusinessCapabilityMainCategory}/${l1BusinessCapabilitySubCategory}`));
  }, [l1BusinessCapabilityMainCategory, l1BusinessCapabilitySubCategory]);

  useEffect(() => {
    dispatch(setDataProductName(name));
    dispatch(setNewProjectName(sanitizeProjectName(name)));
  }, [name]);

  useEffect(() => {
    if (!createNewProject && selectedProject !== null) {
      dispatch(setName(selectedProject.name));
    }
  }, [createNewProject, selectedProject]);

  useEffect(() => {
    const fetchMetadata = async (): Promise<void> => {
      if (selectedProject !== null && selectedProject.name !== '') {
        setMetadataLoading(true);
        const isLoadingFromMainSuccessful = await loadMetadata('main');
        if (!isLoadingFromMainSuccessful) {
          const branchName = getCamelCase(selectedProject.name);
          await loadMetadata(branchName);
        }
        setMetadataLoading(false);
      }
    };

    fetchMetadata().catch((err) => console.log(err));
  }, [selectedProject]);

  const loadMetadata = async (branchName: string): Promise<boolean> => {
    try {
      const response: any = await getMetadataFileContent(
        {
          projectPath: selectedProject.path,
          branch: branchName,
        },
        true,
      ).unwrap();

      console.log(response);

      dispatch(setJiraTicketNo(response.metadata?.jira_ticket_no ?? jiraTicket));
      dispatch(setDescription(response.metadata?.description ?? description));
      dispatch(setOwner(response.metadata?.owner ?? owner));
      dispatch(setDataProductManager(response.metadata?.data_product_manager ?? dataProductManager));
      dispatch(setVersion(response.metadata?.version ?? version));
      dispatch(setVersionDescription(response.metadata?.version_description ?? versionDescription));
      dispatch(setSchemaDescription(response.metadata?.schema_description ?? schemaDescription));
      dispatch(setDataProductCategory(response.metadata?.data_product_category ?? dataProductCategory));
      dispatch(setDataProductType(response.metadata?.data_product_type ?? dataProductType));
      dispatch(setContainsPatientData(response.metadata?.contains_patient_data ?? containsPatientData));
      dispatch(setContainsPersonalData(response.metadata?.contains_personal_data ?? containsPersonalData));
      dispatch(setIsGxpValidated(response.metadata?.is_gxp_validated ?? isGxpValidated));
      dispatch(setIsSoxControlled(response.metadata?.is_sox_controlled ?? isSoxControlled));
      dispatch(setPurpose(response.metadata?.purpose ?? purpose));
      dispatch(setSpecification1(response.metadata?.specification_1 ?? specification));
      dispatch(setSourceSystem(response.metadata?.source_system ?? sourceSystem));
      dispatch(setL1BusinessCapability(response.metadata?.l1_business_capability ?? l1BusinessCapability));
      dispatch(
        setL1BusinessCapabilityMainCategory(
          response.metadata?.l1_business_capability?.split('/')[0] ?? l1BusinessCapabilityMainCategory,
        ),
      );
      dispatch(
        setL1BusinessCapabilitySubCategory(
          response.metadata?.l1_business_capability?.split('/')[1] ?? l1BusinessCapabilitySubCategory,
        ),
      );
      dispatch(setFormat(response.metadata?.format ?? format));
      dispatch(setDataOffice(response.metadata?.data_office ?? dataOffice));
      dispatch(setApplication(response.metadata?.application ?? application));
      dispatch(setProcessingFrequency(response.metadata?.processing_frequency ?? processingFrequency));
      dispatch(setGradCode(response.metadata?.grad_code ?? gradCode));
      dispatch(setLineOfBusinessOwningDP(response.metadata?.line_of_business_owning_dp ?? lineOfBusinessOwningDP));
      dispatch(setBusinessCapability(response.metadata?.business_capability ?? businessCapability));
      dispatch(setFairProfileName(response.metadata?.fair_profile_name ?? fairProfileName));
      dispatch(setMeetsUseCase(response.metadata?.meets_use_case ?? meetsUseCase));
      dispatch(setUrl(response.metadata?.url ?? url));
      dispatch(setBusinessTerm(response.metadata?.business_term ?? businessTerm));
      dispatch(setBusinessTermDefinition(response.metadata?.business_term_definition ?? businessTermDefinition));

      if (response.account !== undefined) {
        dispatch(setSnowflakeAccount(response.account));
        dispatch(setSnowflakeAccountConnected(true));
        dispatch(setSkipSnowflakeConnectionStep(true));
      }

      if (response.tables !== undefined && Array.isArray(response.tables)) {
        for (const table of response.tables as ITable[]) {
          const tableKey = `${table.database}.${table.schema}`;
          if (table.database !== undefined && !(table.database in schemas)) {
            const schemasRes: any = await getSchemas({
              account: response.account,
              database: table.database,
            }).unwrap();

            const schemaArray = schemasRes.map((schema: any) => {
              return { database: table.database, schema };
            });
            dispatch(addSchemas({ databaseKey: table.database, schemas: schemaArray }));

            const tablesRes: any = await getSchemaLevelObjects({
              account: response.account,
              database: table.database,
              schema: table.schema,
              object_type: 'table',
            }).unwrap();

            const tableArray = tablesRes.map((tableObj: any) => {
              return { database: table.database, schema: table.schema, table: tableObj };
            });
            dispatch(addTables({ schemaKey: `${table.database}.${table.schema}`, tables: tableArray }));
            dispatch(selectTable(table));
          } else if (table.database !== undefined && table.schema !== undefined && !(tableKey in tables)) {
            const tablesRes: any = await getSchemaLevelObjects({
              account: response.account,
              database: table.database,
              schema: table.schema,
              object_type: 'table',
            }).unwrap();

            const tableArray = tablesRes.map((tableObj: any) => {
              return { database: table.database, schema: table.schema, table: tableObj };
            });
            dispatch(addTables({ schemaKey: `${table.database}.${table.schema}`, tables: tableArray }));
            dispatch(selectTable(table));
          } else {
            dispatch(selectTable(table));
          }
        }
      }

      return true; // Success
    } catch (err) {
      console.log(err);
      return false; // Failure
    }
  };

  const onContinue = (): void => {
    if (formRef.current?.reportValidity() === true) {
      if (description.length < 50) {
        setFailed(true);
        setErrorMessage('Description must be at least 50 characters.');
        return;
      }

      setLoading(true);
      setFailed(false);
      setErrorMessage(defaultErrorMessage);
      let projectName = '';
      if (createNewProject) {
        if (newProjectName === '') return;

        if (!isProjectNameValid(newProjectName)) {
          setFailed(true);
          setErrorMessage(
            'The project name is invalid. Project names must start and end with a letter or digit, avoid consecutive special characters, and include only letters, digits, underscores, dots, or dashes.',
          );
          setLoading(false);
          return;
        }

        const newProjectPath = `${selectedGroup.path}/${newProjectName}`;
        console.log('New project path:', newProjectPath);
        dispatch(setProjectPath(newProjectPath));
        projectName = newProjectPath;
        dispatch(setReviewedByInformationArchitect(false));
      } else {
        if (selectedProject.name === '') return;
        console.log('Selected project: ', selectedProject);
        dispatch(setProjectPath(selectedProject.path));
        projectName = selectedProject.path;
        dispatch(setReviewedByInformationArchitect(true));
      }

      const projectAlredyCreatedInThisSession = projectsCreatedInCurrentSession.includes(projectName);
      const isNewProject = createNewProject && !projectAlredyCreatedInThisSession;

      generateDataProductSpecification({
        projectName,
        isNewProject,
        name,
        description,
        dataQualityTests: '',
        version,
        owner,
        group: selectedGroup.path,
        projectType: 'az',
        governanceData: true,
      })
        .unwrap()
        .then((res: any) => {
          console.log(res);
          dispatch(setBranch(res));
          dispatch(setProjectsCreatedInCurrentSession([...projectsCreatedInCurrentSession, projectName]));
          dispatch(setModels(undefined));
          setSuccess(true);
        })
        .catch((err) => {
          console.log(err);
          if ((err.data as string).includes('Unable to create project. The project name has already been taken')) {
            setErrorMessage('Project already exists.');
          }
          setFailed(true);
        })
        .finally(() => {
          setLoading(false);
        });
    }
  };

  const resetFields = (): void => {
    dispatch(setJiraTicketNo(''));
    dispatch(setDescription(''));
    dispatch(setOwner(''));
    dispatch(setDataProductManager(''));
    dispatch(setVersion(''));
    dispatch(setVersionDescription(''));
    dispatch(setSchemaDescription(''));
    dispatch(setDataProductCategory(''));
    dispatch(setDataProductType(''));
    dispatch(setContainsPatientData(false));
    dispatch(setContainsPersonalData(false));
    dispatch(setIsGxpValidated(false));
    dispatch(setIsSoxControlled(false));
    dispatch(setPurpose(''));
    dispatch(setSpecification1(''));
    dispatch(setSourceSystem(''));
    dispatch(setL1BusinessCapability(''));
    dispatch(setL1BusinessCapabilityMainCategory(''));
    dispatch(setL1BusinessCapabilitySubCategory(''));
    dispatch(setFormat(''));
    dispatch(setDataOffice(''));
    dispatch(setApplication(''));
    dispatch(setProcessingFrequency(''));
    dispatch(setGradCode(''));
    dispatch(setLineOfBusinessOwningDP(''));
    dispatch(setBusinessCapability(''));
    dispatch(setFairProfileName(''));
    dispatch(setMeetsUseCase(''));
    dispatch(setUrl(''));
    dispatch(setBusinessTerm(''));
    dispatch(setBusinessTermDefinition(''));
    dispatch(clearSelectedObjects());
  };

  useEffect(() => {
    if (success) {
      props.onContinue();
    }
  }, [success]);

  return (
    <StepWrapper
      title="Core Data Product attributes"
      subtitle="All form fields are required"
      onBack={() => props.onBack()}
      onContinue={() => onContinue()}
      isLoading={loading}
      continueDisabled={
        loading ||
        (selectedGroup !== null && selectedGroup.name === '') ||
        (createNewProject && newProjectName === '') ||
        (!createNewProject && selectedProject !== null && selectedProject.name === '') ||
        name === '' ||
        owner === '' ||
        dataProductManager === '' ||
        version === '' ||
        versionDescription === '' ||
        schemaDescription === '' ||
        l1BusinessCapability === '' ||
        l1BusinessCapabilityMainCategory === '' ||
        l1BusinessCapabilitySubCategory === '' ||
        fairProfileName === '' ||
        dataProductCategory === '' ||
        application === '' ||
        processingFrequency === '' ||
        lineOfBusinessOwningDP === '' ||
        businessCapability === ''
      }
    >
      <div className="h-full">
        <form ref={formRef}>
          <div className="relative mx-[auto] w-3/4 px-2 pb-2 grid grid-cols-2 gap-6">
            <div className="col-span-2">
              <DividerWithText dividerText="Data Product Set-up" />
            </div>
            <GroupAndProjectSelector
              projectSelectorDisabled={createNewProject}
              onProjectSelectionChanged={() => resetFields()}
            />
            {metadataLoading && (
              <div className="absolute right-4 top-5 min-h-6 flex items-center justify-center">
                <div
                  className="animate-spin inline-block w-6 h-6 border-[2px] border-current border-t-transparent text-cyan-800 rounded-full"
                  role="status"
                  aria-label="loading"
                >
                  <span className="sr-only">Loading...</span>
                </div>
              </div>
            )}

            <div className="col-span-2">
              <DividerWithText dividerText="Data Product Creation Mode" />
            </div>
            <div className="col-span-2 pb-4">
              <Field as="div" className="col-span-2 flex items-center justify-center">
                <Switch
                  checked={isPowerUserMode}
                  onChange={() => dispatch(setIsPowerUserMode(!isPowerUserMode))}
                  className="group relative inline-flex h-5 w-10 flex-shrink-0 cursor-pointer items-center justify-center rounded-full focus:outline-none"
                >
                  <span className="sr-only">Use setting</span>
                  <span aria-hidden="true" className="pointer-events-none absolute h-full w-full rounded-md bg-white" />
                  <span
                    aria-hidden="true"
                    className={classNames(
                      isPowerUserMode ? 'bg-dataops-primary-blue' : 'bg-gray-200',
                      'pointer-events-none absolute mx-auto h-4 w-9 rounded-full transition-colors duration-200 ease-in-out',
                    )}
                  />
                  <span
                    aria-hidden="true"
                    className={classNames(
                      isPowerUserMode ? 'translate-x-5' : 'translate-x-0',
                      'pointer-events-none absolute left-0 inline-block h-5 w-5 transform rounded-full border border-gray-200 bg-white shadow ring-0 transition-transform duration-200 ease-in-out',
                    )}
                  />
                </Switch>
                <Label as="span" className="ml-1 cursor-default flex">
                  <span className="text-sm font-medium text-gray-700">Create Data Product from SQL Query</span>
                  <InformationTooltip tooltip="Use this option if you have SQL queries to create a data model" />
                </Label>
              </Field>
            </div>

            <div className="col-span-2 mt-2">
              <DividerWithText dividerText="Data Product Attributes" />
            </div>
            <div className="col-span-2 xl:col-span-1">
              <label htmlFor="name" className="block text-sm font-medium leading-6 text-gray-900">
                Name*
              </label>
              <div className="mt-2">
                <div className="flex rounded-md shadow-sm">
                  <input
                    type="text"
                    value={name}
                    onChange={(e) => dispatch(setName(e.target.value))}
                    name="name"
                    id="name"
                    autoComplete="name"
                    required
                    className="block rounded-md flex-1 ring-1 ring-inset ring-gray-300 border-0 bg-transparent p-1.5 text-black placeholder:text-gray-400 sm:text-sm disabled:cursor-not-allowed disabled:bg-gray-50 disabled:text-gray-500 disabled:ring-gray-200"
                    placeholder="Customer Insight Dashboard"
                    disabled={!createNewProject}
                  />
                </div>
              </div>
            </div>

            <div className="col-span-2 xl:col-span-1">
              <label htmlFor="owner" className="block text-sm font-medium leading-6 text-gray-900">
                Owner*
              </label>
              <div className="mt-2">
                <div className="flex rounded-md shadow-sm ring-1 ring-inset ring-gray-300">
                  <input
                    type="text"
                    id="owner"
                    name="owner"
                    value={owner}
                    onChange={(e) => dispatch(setOwner(e.target.value))}
                    autoComplete="owner"
                    placeholder="Jane Doe"
                    required
                    className="block flex-1 border-0 bg-transparent p-1.5 text-black placeholder:text-gray-400 focus:ring-0 sm:text-sm sm:leading-6"
                  />
                </div>
              </div>
            </div>

            <div className="col-span-2">
              <label htmlFor="description" className="block text-sm font-medium leading-6 text-gray-900">
                Description* <span className="text-gray-600">(min 50 characters)</span>
              </label>
              <div className="mt-2">
                <textarea
                  id="decription"
                  name="description"
                  value={description}
                  onChange={(e) => dispatch(setDescription(e.target.value))}
                  rows={4}
                  required
                  className="block w-full rounded-md border-0 p-1.5 text-sm text-black placeholder:text-gray-400 shadow-sm ring-1 ring-inset ring-gray-300"
                  placeholder="This data product consolidates customer interaction data across multiple channels – including sales, customer service, and online engagement – to provide a comprehensive view of customer behaviours, preferences, and feedback. We will use this dashboard to segment our customer base, identify trends, and tailor our marketing strategies effectively and make data-driven decisions that enhance customer satisfaction and drive business growth."
                />
              </div>
            </div>

            <div className="col-span-2 xl:col-span-1">
              <label htmlFor="owner" className="block text-sm font-medium leading-6 text-gray-900">
                Data Product Manager*
              </label>
              <div className="mt-2">
                <div className="flex rounded-md shadow-sm ring-1 ring-inset ring-gray-300">
                  <input
                    type="text"
                    id="owner"
                    name="owner"
                    value={dataProductManager}
                    onChange={(e) => dispatch(setDataProductManager(e.target.value))}
                    autoComplete="owner"
                    placeholder="Jane Doe"
                    required
                    className="block flex-1 border-0 bg-transparent p-1.5 text-black placeholder:text-gray-400 focus:ring-0 sm:text-sm sm:leading-6"
                  />
                </div>
              </div>
            </div>

            <div className="col-span-2 xl:col-span-1">
              <label htmlFor="version" className="block text-sm font-medium leading-6 text-gray-900">
                Version ID*
              </label>
              <div className="mt-2">
                <div className="flex rounded-md shadow-sm ring-1 ring-inset ring-gray-300">
                  <input
                    type="text"
                    id="version"
                    name="version"
                    value={version}
                    onChange={(e) => dispatch(setVersion(e.target.value))}
                    autoComplete="version"
                    placeholder="0.1.0"
                    required
                    className="block flex-1 border-0 bg-transparent p-1.5 text-black placeholder:text-gray-400 focus:ring-0 sm:text-sm sm:leading-6"
                  />
                </div>
              </div>
            </div>

            <div className="col-span-2">
              <label htmlFor="description" className="block text-sm font-medium leading-6 text-gray-900">
                Version description*
              </label>
              <div className="mt-2">
                <textarea
                  id="decription"
                  name="description"
                  value={versionDescription}
                  onChange={(e) => dispatch(setVersionDescription(e.target.value))}
                  rows={2}
                  required
                  className="block w-full rounded-md border-0 p-1.5 text-sm text-black placeholder:text-gray-400 shadow-sm ring-1 ring-inset ring-gray-300"
                  placeholder="Version description"
                />
              </div>
            </div>

            <div className="col-span-2">
              <label htmlFor="schema" className="block text-sm font-medium leading-6 text-gray-900">
                Schema description*
              </label>
              <div className="mt-2">
                <textarea
                  id="schema"
                  name="schema"
                  value={schemaDescription}
                  onChange={(e) => dispatch(setSchemaDescription(e.target.value))}
                  rows={2}
                  required
                  className="block w-full rounded-md border-0 p-1.5 text-sm text-black placeholder:text-gray-400 shadow-sm ring-1 ring-inset ring-gray-300"
                  placeholder="Schema description"
                />
              </div>
            </div>

            <div className="col-span-2 xl:col-span-1">
              <label htmlFor="name" className="block text-sm font-medium leading-6 text-gray-900">
                FAIR Profile Name*
              </label>
              <div className="mt-2">
                <div className="flex rounded-md shadow-sm ring-1 ring-inset ring-gray-300">
                  <input
                    type="text"
                    value={fairProfileName}
                    onChange={(e) => dispatch(setFairProfileName(e.target.value))}
                    name="name"
                    id="name"
                    autoComplete="name"
                    required
                    className="block flex-1 border-0 bg-transparent p-1.5 text-black placeholder:text-gray-400 focus:ring-0 sm:text-sm sm:leading-6"
                  />
                </div>
              </div>
            </div>

            <div className="col-span-2 xl:col-span-1">
              <DropdownSingleselect
                label="Business Capability*"
                options={['Develop', 'CM&S', 'GSC&S', 'Make', 'Master', 'Quality', 'GERE', 'Procurement']}
                selected={businessCapability}
                setSelected={(value: string) => dispatch(setBusinessCapability(value))}
              />
            </div>

            <div className="col-span-2 xl:col-span-1">
              <DropdownSingleselect
                label="L1 Business Capability*"
                options={['Make & Supply Products', 'Discover & Develop Drug', 'Manage & Enable AZ', 'Market & Sell']}
                selected={l1BusinessCapabilityMainCategory}
                setSelected={(value: string) => dispatch(setL1BusinessCapabilityMainCategory(value))}
              />
            </div>

            <div className="col-span-2 xl:col-span-1">
              <DropdownSingleselect
                label="L1 Business Capability Sub-category*"
                options={subCategoryOptions}
                selected={l1BusinessCapabilitySubCategory}
                setSelected={(value: string) => dispatch(setL1BusinessCapabilitySubCategory(value))}
              />
            </div>

            <div className="col-span-2 xl:col-span-1">
              <DropdownSingleselect
                label="Data Product Category*"
                options={['Foundational', 'Derived']}
                selected={dataProductCategory}
                setSelected={(value: string) => dispatch(setDataProductCategory(value))}
              />
            </div>

            <div className="col-span-2 xl:col-span-1">
              <label htmlFor="name" className="block text-sm font-medium leading-6 text-gray-900">
                Application*
              </label>
              <div className="mt-2">
                <div className="flex rounded-md shadow-sm ring-1 ring-inset ring-gray-300">
                  <input
                    type="text"
                    value={application}
                    onChange={(e) => dispatch(setApplication(e.target.value))}
                    name="name"
                    id="name"
                    autoComplete="name"
                    required
                    className="block flex-1 border-0 bg-transparent p-1.5 text-black placeholder:text-gray-400 focus:ring-0 sm:text-sm sm:leading-6"
                  />
                </div>
              </div>
            </div>

            <div className="col-span-2 xl:col-span-1">
              <DropdownSingleselect
                label="Line of Business Owning DP*"
                options={['Operations', 'R&D', 'Commercial', 'Enabling', 'Alexion']}
                selected={lineOfBusinessOwningDP}
                setSelected={(value: string) => dispatch(setLineOfBusinessOwningDP(value))}
              />
            </div>

            <div className="col-span-2 xl:col-span-1">
              <DropdownSingleselect
                label="Processing Frequency*"
                options={['Daily', 'Weekly', 'Monthly']}
                selected={processingFrequency}
                setSelected={(value: string) => dispatch(setProcessingFrequency(value))}
              />
            </div>

            <div className="col-span-2">
              <div className="w-1/2 flex flex-col items-start">
                <Field as="div" className="flex items-center justify-center">
                  <Switch
                    checked={containsPatientData}
                    onChange={() => dispatch(setContainsPatientData(!containsPatientData))}
                    className="group relative inline-flex h-5 w-10 flex-shrink-0 cursor-pointer items-center justify-center rounded-full focus:outline-none"
                  >
                    <span className="sr-only">Use setting</span>
                    <span
                      aria-hidden="true"
                      className="pointer-events-none absolute h-full w-full rounded-md bg-white"
                    />
                    <span
                      aria-hidden="true"
                      className={classNames(
                        containsPatientData ? 'bg-dataops-primary-blue' : 'bg-gray-200',
                        'pointer-events-none absolute mx-auto h-4 w-9 rounded-full transition-colors duration-200 ease-in-out',
                      )}
                    />
                    <span
                      aria-hidden="true"
                      className={classNames(
                        containsPatientData ? 'translate-x-5' : 'translate-x-0',
                        'pointer-events-none absolute left-0 inline-block h-5 w-5 transform rounded-full border border-gray-200 bg-white shadow ring-0 transition-transform duration-200 ease-in-out',
                      )}
                    />
                  </Switch>
                  <Label as="span" className="ml-1 cursor-default">
                    <span className="text-sm font-medium text-gray-700">Contains Patient Data</span>
                  </Label>
                </Field>

                <Field as="div" className="flex items-center justify-center">
                  <Switch
                    checked={containsPersonalData}
                    onChange={() => dispatch(setContainsPersonalData(!containsPersonalData))}
                    className="group relative inline-flex h-5 w-10 flex-shrink-0 cursor-pointer items-center justify-center rounded-full focus:outline-none"
                  >
                    <span className="sr-only">Use setting</span>
                    <span
                      aria-hidden="true"
                      className="pointer-events-none absolute h-full w-full rounded-md bg-white"
                    />
                    <span
                      aria-hidden="true"
                      className={classNames(
                        containsPersonalData ? 'bg-dataops-primary-blue' : 'bg-gray-200',
                        'pointer-events-none absolute mx-auto h-4 w-9 rounded-full transition-colors duration-200 ease-in-out',
                      )}
                    />
                    <span
                      aria-hidden="true"
                      className={classNames(
                        containsPersonalData ? 'translate-x-5' : 'translate-x-0',
                        'pointer-events-none absolute left-0 inline-block h-5 w-5 transform rounded-full border border-gray-200 bg-white shadow ring-0 transition-transform duration-200 ease-in-out',
                      )}
                    />
                  </Switch>
                  <Label as="span" className="ml-1 cursor-default">
                    <span className="text-sm font-medium text-gray-700">Contains Personal Data</span>
                  </Label>
                </Field>

                <Field as="div" className="flex items-center justify-center">
                  <Switch
                    checked={isGxpValidated}
                    onChange={() => dispatch(setIsGxpValidated(!isGxpValidated))}
                    className="group relative inline-flex h-5 w-10 flex-shrink-0 cursor-pointer items-center justify-center rounded-full focus:outline-none"
                  >
                    <span className="sr-only">Use setting</span>
                    <span
                      aria-hidden="true"
                      className="pointer-events-none absolute h-full w-full rounded-md bg-white"
                    />
                    <span
                      aria-hidden="true"
                      className={classNames(
                        isGxpValidated ? 'bg-dataops-primary-blue' : 'bg-gray-200',
                        'pointer-events-none absolute mx-auto h-4 w-9 rounded-full transition-colors duration-200 ease-in-out',
                      )}
                    />
                    <span
                      aria-hidden="true"
                      className={classNames(
                        isGxpValidated ? 'translate-x-5' : 'translate-x-0',
                        'pointer-events-none absolute left-0 inline-block h-5 w-5 transform rounded-full border border-gray-200 bg-white shadow ring-0 transition-transform duration-200 ease-in-out',
                      )}
                    />
                  </Switch>
                  <Label as="span" className="ml-1 cursor-default">
                    <span className="text-sm font-medium text-gray-700">Is GxP Validated</span>
                  </Label>
                </Field>

                <Field as="div" className="flex items-center justify-center">
                  <Switch
                    checked={isSoxControlled}
                    onChange={() => dispatch(setIsSoxControlled(!isSoxControlled))}
                    className="group relative inline-flex h-5 w-10 flex-shrink-0 cursor-pointer items-center justify-center rounded-full focus:outline-none"
                  >
                    <span className="sr-only">Use setting</span>
                    <span
                      aria-hidden="true"
                      className="pointer-events-none absolute h-full w-full rounded-md bg-white"
                    />
                    <span
                      aria-hidden="true"
                      className={classNames(
                        isSoxControlled ? 'bg-dataops-primary-blue' : 'bg-gray-200',
                        'pointer-events-none absolute mx-auto h-4 w-9 rounded-full transition-colors duration-200 ease-in-out',
                      )}
                    />
                    <span
                      aria-hidden="true"
                      className={classNames(
                        isSoxControlled ? 'translate-x-5' : 'translate-x-0',
                        'pointer-events-none absolute left-0 inline-block h-5 w-5 transform rounded-full border border-gray-200 bg-white shadow ring-0 transition-transform duration-200 ease-in-out',
                      )}
                    />
                  </Switch>
                  <Label as="span" className="ml-1 cursor-default">
                    <span className="text-sm font-medium text-gray-700">Is SOx Controlled</span>
                  </Label>
                </Field>
              </div>
            </div>
          </div>
        </form>
        <div className="w-3/4 mx-[auto]">
          <LoadingAndErrorSection
            isLoading={loading}
            isFailed={failed}
            errorMessage={errorMessage}
            hideLoading={true}
          />
        </div>
      </div>
    </StepWrapper>
  );
}
