import React, { useMemo } from 'react';
import { useAppSelector } from 'app/hooks';
import { selectSelectedDatabases, selectSelectedSchemas, selectSelectedTables } from '../reducers/builderSlice';
import DatabaseItem from './DatabaseItem';

interface SourceSelectorProps {
  mode: 'selection' | 'preview';
  highlightedDatabase?: string;
  databases: string[];
  objectType: 'table' | 'view';
}

export default function SourceSelector(props: SourceSelectorProps): JSX.Element {
  const selectedDatabases = useAppSelector(selectSelectedDatabases);
  const selectedSchemas = useAppSelector(selectSelectedSchemas);
  const selectedTables = useAppSelector(selectSelectedTables);

  const databases = useMemo(() => {
    if (props.mode === 'selection') {
      let tmpDatabases = [...props.databases];

      if (props.highlightedDatabase !== undefined && tmpDatabases.includes(props.highlightedDatabase)) {
        tmpDatabases = [props.highlightedDatabase, ...tmpDatabases.filter((db) => db !== props.highlightedDatabase)];
      }

      return tmpDatabases;
    }

    const selectedDatabasesFlattened = selectedDatabases.map((database) => database.database);
    const databasesWithSelectedSchemas = selectedSchemas.map((schema) => schema.database);
    const databasesWithSelectedTables = selectedTables.map((table) => table.database);

    const mergedDatabaseList = [
      ...new Set([...selectedDatabasesFlattened, ...databasesWithSelectedSchemas, ...databasesWithSelectedTables]),
    ];

    if (props.highlightedDatabase !== undefined && mergedDatabaseList.includes(props.highlightedDatabase)) {
      const filteredList = mergedDatabaseList.filter((db) => db !== props.highlightedDatabase);
      return [props.highlightedDatabase, ...filteredList];
    }

    return mergedDatabaseList;
  }, [props.databases, selectedDatabases, selectedSchemas, selectedTables]);

  return (
    <>
      <div className="w-full max-w-[25rem] flex flex-col items-center space-y-1">
        {databases.map((database) => (
          <DatabaseItem
            key={database}
            databaseName={database}
            openByDefault={databases.length < 4}
            mode={props.mode}
            highlighted={database === props.highlightedDatabase}
            objectType={props.objectType}
          />
        ))}
      </div>
      {props.mode === 'preview' && databases.length === 0 && <div className="text-gray-600">No objects selected</div>}
    </>
  );
}
